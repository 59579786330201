import { Col, Row } from "antd";
import { ILegalTerms } from "./ILegalTerms";
import "./LegalTerms.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";

const LegalTerms = ({ templateData, translations, formatNumber }: ILegalTerms) => (
  <div id="legalTerms" contentEditable className="pagebreak">
    <ReportHeader
      confidential={translations.confidential}
      programName={templateData.programName}
      date={`${translations[templateData.month]} ${templateData.year}`}
      section={translations.section}
      page={templateData.page}
    />
    <Row className="content" align="top" gutter={[0, 0]}>
      <Col span={24}>
        <main className="legalTerms">
          <section className="legalTerms-content">
            <div>
              <p className="legalTerms-subtitle">{translations.description}</p>
              <p className="legalTerms-content-text">
                {templateData.description}
              </p>
            </div>
            <div>
              <p className="legalTerms-subtitle">{translations.fundStrategy}</p>
              <div className="legalTerms-content-table">
                <div className="legalTerms-content-row odd">
                  <p>{translations.type}</p>
                  <p>{templateData.type}</p>
                </div>
                <div className="legalTerms-content-row ">
                  <p>{translations.target}</p>
                  <p>{templateData.targetSize}</p>
                </div>
                <div className="legalTerms-content-row odd">
                  <p>{translations.diversificationF}</p>
                  <p>{templateData.nFunds}</p>
                </div>
                <div className="legalTerms-content-row ">
                  <p>{translations.diversificationC}</p>
                  <p>{templateData.nCompanies}</p>
                </div>
                <div className="legalTerms-content-row odd">
                  <p>{translations.geography}</p>
                  <p>{templateData.geography}</p>
                </div>
                <div className="legalTerms-content-row ">
                  <p>{translations.strategy}</p>
                  <p>{templateData.stage}</p>
                </div>
                <div className="legalTerms-content-row odd">
                  <p>{translations.currency}</p>
                  <p>{templateData.currency}</p>
                </div>
              </div>
            </div>
            <div>
              <p className="legalTerms-subtitle">{translations.fees}</p>
              <div className="legalTerms-content-table">
                <div className="legalTerms-content-row odd">
                  <p>{translations.duringPeriod}</p>
                  <p>
                  {templateData.feeDuring}
                  </p>
                </div>
                <div className="legalTerms-content-row ">
                  <p>{translations.afterPeriod}</p>
                  <p>
                  {templateData.feeAfter}
                  </p>
                </div>
                <div className="legalTerms-content-row odd">
                  <p>{translations.carried}</p>
                  <p>
                    {templateData.carriedInterest1}
                    <br />
                    {templateData.carriedInterest2}
                    <br />
                    {templateData.carriedInterest3}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="legalTerms-content">
            <div>
              <p className="legalTerms-subtitle">{translations.overview}</p>
              <div className="legalTerms-content-table">
                <div className="legalTerms-content-row ">
                  <p>{translations.fullName}</p>
                  <p>{templateData.fullName}</p>
                </div>
                <div className="legalTerms-content-row odd">
                  <p>{translations.isin}</p>
                  <p>{templateData.isin}</p>
                </div>
                <div className="legalTerms-content-row ">
                  <p>{translations.firstDate} </p>
                  <p>{templateData.firstClosing}</p>
                </div>
                <div className="legalTerms-content-row odd">
                  <p>{translations.finalDate}</p>
                  <p>{templateData.finalClosing} </p>
                </div>
                <div className="legalTerms-content-row ">
                  <p>{translations.vintage} </p>
                  <p>{templateData.vintage}</p>
                </div>
                <div className="legalTerms-content-row odd">
                  <p>{translations.firstCommitment} </p>
                  <p>{templateData.firstCommitment}</p>
                </div>
                <div className="legalTerms-content-row ">
                  <p> {translations.term12}</p>
                  <p>{templateData.termTwelve}</p>
                </div>
                <div className="legalTerms-content-row odd">
                  <p>{translations.termExt}</p>
                  <p>{templateData.termExt}</p>
                </div>
                <div className="legalTerms-content-row ">
                  <p>{translations.investmentPeriod}</p>
                  <p>{templateData.investmentPeriod}</p>
                </div>
                <div className="legalTerms-content-row odd">
                  <p>{translations.investmentPeriodExt} </p>
                  <p>{templateData.investmentPeriodExt}</p>
                </div>
                <div className="legalTerms-content-row ">
                  <p>{translations.fundCurrency}</p>
                  <p>{templateData.fundCurrency}</p>
                </div>
                <div className="legalTerms-content-row odd">
                  <p>{translations.totalCurrentCommitments}</p>
                  <p>€{formatNumber(templateData.totalCurrentCommitment, 0)}</p>
                </div>
                <div className="legalTerms-content-row ">
                  <p>{translations.domicile}</p>
                  <p>{templateData.domicile}</p>
                </div>
                <div className="legalTerms-content-row odd">
                  <p>{translations.legalForm}</p>
                  <p>{templateData.legalForm}</p>
                </div>
                <div className="legalTerms-content-row">
                  <p>{translations.generalPartner}</p>
                  <p>{templateData.generalPartner}</p>
                </div>
                <div className="legalTerms-content-row odd">
                  <p>{translations.maxSingleInvest}</p>
                  <p>{templateData.maxSingleInvestment} </p>
                </div>
                <div className="legalTerms-content-row">
                  <p>{translations.oversubscription}</p>
                  <p>{templateData.oversubscription}</p>
                </div>
              </div>
            </div>
            <div>
              <p className="legalTerms-subtitle">
              {translations.directive}
              </p>
              <div className="legalTerms-content-table">
                <div className="legalTerms-content-row odd">
                  <p>{translations.regulators}</p>
                  <p>{templateData.regulators}</p>
                </div>
                <div className="legalTerms-content-row">
                  <p>{translations.depositary}</p>
                  <p>{templateData.depositary}</p>
                </div>
                <div className="legalTerms-content-row odd">
                  <p>{translations.registration}</p>
                  <p>{templateData.registrationCountry}</p>
                </div>
                <div className="legalTerms-content-row ">
                  <p>{translations.riskManager}</p>
                  <p>{templateData.externalRiskManager}</p>
                </div>
                <div className="legalTerms-content-row odd">
                  <p>{translations.auditor}</p>
                  <p>{templateData.auditor}</p>
                </div>
                <div className="legalTerms-content-row">
                  <p>{translations.lawyer}</p>
                  <p>{templateData.lawyer}</p>
                </div>
              </div>
            </div>
            <div>
              <p className="legalTerms-subtitle">{translations.committees}</p>
              <div className="legalTerms-content-table">
                <div className="legalTerms-content-row odd">
                  <p>{translations.invComm}</p>
                  <p>
                    {templateData.commmittee1}
                    <br />
                    {templateData.commmittee2}
                    <br />
                    {templateData.commmittee3}
                    <br />
                    {templateData.commmittee4}
                    <br />
                    {templateData.commmittee5}
                  </p>
                </div>
                <div className="legalTerms-content-row">
                  <p>{translations.supervisory}</p>
                  <p>
                  {templateData.supervisoryCommittee}
                  </p>
                </div>
                <div className="legalTerms-content-row odd">
                  <p>{translations.keyMen}</p>
                  <p>
                    {templateData.keyMan1}
                    <br />
                    {templateData.keyMan2}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
        <p className="legalTerms-note">
        {translations.note}
        </p>
      </Col>
    </Row>
  </div>
);

export default LegalTerms;
