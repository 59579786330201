import { Button, Row, Select } from "antd";

import Cover from "../sections/Common/00-Cover/Cover";
import FundSummary from "../sections/Direct/01-FundSummary/FundSummary";
import FundFigures from "../sections/Direct/02-FundFigures/FundFigures";
import FundEvolution from "../sections/Direct/03-FundEvolution/FundEvolution";
import FundCharts from "../sections/Direct/04-FundCharts/FundCharts";
import PortfolioPerformance from "../sections/Direct/05-PortfolioPerformance/PortfolioPerformance";
import CoinvestDetail from "../sections/Direct/06-CoinvestDetail/CoinvestDetail";
import CoinvestCover from "../sections/Direct/07-CoinvestCover/CoinvestCover";
import CoinvestAcquisition from "../sections/Direct/08-CoinvestAcquisition/CoinvestAcquisition";
import Glossary from "../sections/Common/00-Glossary/Glossary";

import usePreview from "../../hooks/usePreview";

import { IPreview } from "./IPreview";

import { languages } from "../../assets/languages";
import FundFiguresFoF from "../sections/FoF/02-FundFiguresFoF/FundFiguresFoF";
import PortfolioFunds from "../sections/FoF/08-PortfolioFunds/PortfolioFunds";
import CoinvestSummary from "../sections/FoF/10-CoinvestSummary/CoinvestSummary";
import LegalTerms from "../sections/FoF/13-LegalTerms/LegalTerms";
import IndexFoF from "../sections/FoF/00-IndexFoF/IndexFoF";
import Index from "../sections/Direct/00-Index/Index";
import UnderlyingFunds from "../sections/FoF/04-UnderlyingFunds/UnderlyingFunds";
import CoinvestTable from "../sections/FoF/09-CoinvestTable/CoinvestTable";
import PortfolioDiversification from "../sections/FoF/05-PortfolioDiversification/PortfolioDiversification";
import NewInvestments from "../sections/FoF/06-NewInvestments/NewInvestments";
import CompaniesList from "../sections/FoF/12-CompaniesList/CompaniesList";
import FundSummaryFoF from "../sections/FoF/01-FundSummaryFoF/FundSummaryFoF";
import PortfolioInvestments from "../sections/FoF/03-PortfolioInvestments/PortfolioInvestments";
import PortfolioBreakdown from "../sections/FoF/11-PorfolioBreakdown/PortfolioBreakdown";
import NewFunds from "../sections/FoF/07-NewFunds/NewFunds";

const Preview = ({ selectedSections, reportData, selectedLanguage, isFoF, reportName, pageNumbers, formatNumber}: IPreview) => {
	const {
		loading,
		previewSectionSelected,
		previewSections,
		previewRef,

		downloadReport,
		handlePreviewSectionSelected
	} = usePreview({ selectedSections, reportName });

	let page = 0;
	return (
		<>
			<Row className="title" justify="start" align="middle">
				<h3>PREVIEW</h3>
				<span>Select a section to preview it</span>
				<Button className="save-version-btn" onClick={downloadReport} loading={loading}>Download report</Button>
			</Row>
			<Row className="preview-selector">
				<Select
					value={previewSectionSelected}
					onChange={handlePreviewSectionSelected}
					options={previewSections}
					placeholder="Select a section to preview"
				/>
			</Row>
			<iframe id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute' }}></iframe>
			<div className="template-container" id="wrapper" ref={previewRef} style={{ backgroundColor: 'white' }}>
				{
					!isFoF && selectedSections.map((section: any) => {
						switch (section.sectionId) {
							case 'cover':
								return (
									<Cover
										templateData={reportData.cover}
										translations={languages[selectedLanguage].cover}
									/>
								);
							case 'index': 
								page = 2; 
								return (
									<Index
										templateData={{ ...reportData.cover, ...pageNumbers, page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].index }}
									/>
								)
							case 'atAGlance': 
								page = page + 1;
								return (
									<FundSummary
										templateData={{ ...reportData.cover, ...reportData.fundSummary, page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].fundSummary }}
										formatNumber={formatNumber}
									/>
								);
							case 'fundFigures': 
								page = page + pageNumbers.section1Pages;
								return (
									<FundFigures
										templateData={{ ...reportData.cover, ...reportData.fundFigures, page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].fundFigures }}
										formatNumber={formatNumber}
									/>
								);
							case 'fundEvolution': 
								page = page + pageNumbers.section2Pages;
								return (
									<FundEvolution
										templateData={{ ...reportData.cover, ...reportData.fundEvolution, page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].fundEvolution }}
										formatNumber={formatNumber}
									/>
								);
							case 'fundCharts': 
								page = page + pageNumbers.section3Pages;
								return (
									<FundCharts
										templateData={{ ...reportData.cover, ...reportData.fundCharts, page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].fundCharts }}
										formatNumber={formatNumber}
									/>
								);
							case 'portfolioPerformance': 
								page = page + pageNumbers.section4Pages;
								return (
									<PortfolioPerformance
										templateData={{ ...reportData.cover, ...reportData.portfolioPerformance, page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].portfolioPerformance }}
										formatNumber={formatNumber}
									/>
								);
							case 'coinvestDetail':
								page = page + pageNumbers.section5Pages;
								return (
									<CoinvestDetail
										templateData={{ ...reportData.cover, coinvestDetail: [...reportData.coinvestDetail], page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].coinvestDetail }}
										formatNumber={formatNumber}
									/>
								);
							case 'coinvestCover': 
								page = page + 1;
								return (
									<CoinvestCover
										templateData={reportData.cover}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].coinvestCover }}
									/>
								);
							case 'coinvestAcquisition':
								page = page + pageNumbers.section6Pages;
								if (reportData.coinvestAcquisition.coinvestDetail) {
									return (
										<CoinvestAcquisition
											templateData={{ ...reportData.cover, ...reportData.coinvestAcquisition, page }}
											translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].coinvestAcquisition }}
											formatNumber={formatNumber}
										/>
									);
								}
								break;
							case 'glossary':
								page = page + pageNumbers.section7Pages;
								return (
									<Glossary
										language={selectedLanguage}
										templateData={{ ...reportData.cover, ...reportData.glossary, page }}
										translations={languages[selectedLanguage].cover}
									/>
								);
							default:
								return <></>;
						}
					})
				}
				{
					isFoF && selectedSections.map((section: any) => {

						switch (section.sectionId) {
							case 'cover':
								return (
									<Cover
										templateData={reportData.cover}
										translations={languages[selectedLanguage].cover}
									/>
								);
							case 'index':
								page = 2; 
								return (
									<IndexFoF
										templateData={{ ...reportData.cover, ...pageNumbers}}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].index }}
									/>
								);
							case 'atAGlance': 
								page = page + 1;
								return (
									<FundSummaryFoF
										templateData={{ ...reportData.cover, ...reportData.fundSummary, page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].fundSummary }}
										formatNumber={formatNumber}
									/>
								);
							case 'fundFigures': 
								page = page + pageNumbers.section1Pages; 
								return (
									<FundFiguresFoF
										templateData={{ ...reportData.cover, ...reportData.fundFigures, ...reportData.fundEvolution, page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].fundFigures }}
										formatNumber={formatNumber}
									/>
								);
							case 'portfolioInvestments':
								page = page + pageNumbers.section2Pages;  
								return (
									<PortfolioInvestments
										templateData={{ ...reportData.cover, ...reportData.portfolioInvestments, page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].portfolioInvestments }}
										formatNumber={formatNumber}
									/>
								);
							case 'underlyingFunds': 
								page = page + pageNumbers.section3Pages; 
								return (
									<UnderlyingFunds
										templateData={{ ...reportData.cover, ...reportData.underlyingFunds, page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].underlyingFunds }}
										formatNumber={formatNumber}
									/>
								);
							case 'portfolioDiversification': 
								page = page + pageNumbers.section4Pages;
								return (
									<PortfolioDiversification
										templateData={{ ...reportData.cover, ...reportData.portfolioDiversification, page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].portfolioDiversification }}
										formatNumber={formatNumber}
									/>
								)
							case 'newInvestments':
								page = page + pageNumbers.section5Pages;
								return (
									<NewInvestments
										templateData={{ ...reportData.cover, ...reportData.newInvestments, page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].newInvestments }}
										formatNumber={formatNumber}
									/>
								)
							case 'newFunds': 
								page = page + pageNumbers.section6Pages;
								return (
									<NewFunds
										templateData={{ ...reportData.cover, newFunds: [...reportData.newFunds], page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].newFunds }}
										formatNumber={formatNumber}
									/>
								)
							case 'portfolioFunds':
								page = page + pageNumbers.section7Pages;
								return (
									<PortfolioFunds
										templateData={{ ...reportData.cover, portfolioFunds: [...reportData.portfolioFunds], page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].portfolioFunds }}
										formatNumber={formatNumber}
									/>
								);
							case 'coinvestmentsTable':
								page = page + pageNumbers.section8Pages;
								return (
									<CoinvestTable
										templateData={{ ...reportData.cover, ...reportData.coinvestTable, page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].coinvestTable }}
										formatNumber={formatNumber}
									/>
								);
							case 'coinvestmentsSummary':
								page = page + pageNumbers.section9Pages;
								return (
									<CoinvestSummary
										templateData={{ ...reportData.cover, coinvestSummary: [...reportData.coinvestSummary], page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].coinvestSummary }}
										formatNumber={formatNumber}
									/>
								);
							case 'portfolioBreakdown':
								page = page + pageNumbers.section10Pages;
								return (
									<PortfolioBreakdown
										templateData={{ ...reportData.cover, portfolioBreakdown: [...reportData.portfolioBreakdown], page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].portfolioBreakdown }}
										formatNumber={formatNumber}
									/>
								);
							case 'companiesList':
								page = page + pageNumbers.section11Pages;
								return (
									<CompaniesList
										templateData={{ ...reportData.cover, companiesList: [...reportData.companiesList], page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].companiesList }}
										formatNumber={formatNumber}
									/>
								);
							case 'fundDescription':
								page = page + pageNumbers.section12Pages;
								return (
									<LegalTerms
										templateData={{ ...reportData.cover, ...reportData.legalTerms, page }}
										translations={{ ...languages[selectedLanguage].cover, ...languages[selectedLanguage].legalTerms }}
										formatNumber={formatNumber}
									/>
								);
							case 'glossary': 
								page = page + pageNumbers.section13Pages;
								return (
									<Glossary
										language={selectedLanguage}
										templateData={{ ...reportData.cover, ...reportData.glossary, page }}
										translations={languages[selectedLanguage].cover}
									/>
								);
							default:
								return <></>;
						}
					})

				}
			</div>
		</>
	);
};

export default Preview;