interface LanguageData {
	[key: string]: string;
}

interface LanguageSection {
	[key: string]: LanguageData;
}

interface Languages {
	[key: string]: LanguageSection;
}

const languages: Languages = {
	es: {
		cover: {
			coverSubtitle: "Expertos en el lower mid-market europeo",
			coverTitle: "QEP Mutual Private Equity",
			coverDate: "Informe trimestral ",
			coverConfidential: "Estrictamente confidencial",
			march: "Marzo",
			june: "Junio",
			september: "Septiembre",
			december: "Diciembre",
			confidential: "CONFIDENCIAL"
		},
		index:{
			section: "Contenido",
			content: "Contenido",
			theFund: "El Fondo - A vista de pájaro",
			mainFigures: "Principales magnitudes",
			fundFiguresUpdate: "Evolución de las principales magnitudes del Fondo",
			investmentDivestment: "Inversiones / Desinversiones",
			portfolioInvestmentsInDetail: "Detalle de las inversiones en cartera",
			compositionOfPortfolio: "Composición de la cartera del Fondo",
			underlyingFundsCoinvestments: "Fondos subyacentes y coinversiones",
			portfolioCompaniesInDetail: "Detalle de empresas en cartera",
			portfolioDiversificationAnalysis: "Análisis de empresas en cartera",
			newInvestmentsRealizations: "Nuevas inversiones y desinversiones",
			newFunds: "Nuevos fondos en cartera",
			portfolioFundsInDetail: "Detalle de fondos en cartera",
			coInvestmentsSummary: "Resumen de coinversiones",
			coInvestmentsSummaryTable: "Tabla resumen de coinversiones",
			coInvestmentsInDetail: "Fichas de coinversiones",
			fullPortfolioBreakdown: "Desglose completo de la cartera",
			alphabeticalListing: "Listado alfabético de las empresas en cartera",
			fundDescriptionLegalTerms: "Descripción del Fondo / Términos legales",
			termsGlossary: "Glosario de términos",
			activityOfTheFund: "Actividad del Fondo",
			portfolio: "Cartera",
			valuation: "Valoración",
			diversificationAnalysis: "Análisis de diversificación",
			mainKpis: "Principales magnitudes del Fondo sobre compromiso",
			portfolioPerformance: "Rendimiento de la cartera",
			portfolioCompanies: "Coinversiones en detalle",
			recentPortfolioCompanies: "Compañías adquiridas recientemente",
		},
		portfolioFunds: {
			section: "Detalle de fondos en cartera",
			summary: "Resumen de la inversión",
			size: "Tamaño (M)",
			commitment: "Compromiso (M)",
			origin: "Origen",
			target: "Enfoque",
			date: "Fecha inversión",
			strategy: "Estrategia",
			type: "Tipo",
			sector: "Sector",
			region: "Región",
			fundPerc: "Fondo",
			
			totalCommitment: "Capital comprometido",
			totalDrawdowns:  "Total desembolsos",
			pendingDrawdowns: "Desembolsos pendientes",
			otherAdjustments: "Otros ajustes",
			totalValue: "Valor total (TVPI)",
			nav: "NAV + Distribuciones",
			distributions: "Distribuciones (DPI)",
			residual: "Valor residual (RPI)",
			
			activity: "Evolución de la actividad inversora",
			evolution: "Último trimestre",
			drawdownVsCommitment: "Desembolsado vs. comprometido",
			current: "Trim actual",
			previous: "Trim anterior",
			pending: "Pendiente",
			drawdownVsDistributions: "Desembolsos vs. distribuciones",
			drawdown: "Desembolsos",
			distribution: "Distribuciones",
			tvpi: "TVPI",
			currentVsPrev: "Período actual vs. anterior",
			remarkable: "Hechos relevantes durante el período",
			
			analysis: "Análisis de la cartera",
			state: "ESTADO",
			fund: "FONDO",
			name: "NOMBRE",
			country: "PAÍS Y SECTOR",
			ev: "EV",
			dateInv: "FECHA INV.",
			holdingPeriod: "PERÍODO TENENCIA",
			investment: "INVERSIÓN",
			opinion: "OPINIÓN GESTOR",
			ref1: "*NI = nueva inversión | C = En cartera | NR = Nueva realización | R = Realizada",
			ref2: "**Distribuciones",
			ref3: "Valor residual",
			option1: "Muy favorable",
			option2: "En plan",
			option3: "Watchlist",
			option4: "En riesgo",
			years: "años",
			rpi: "RPI"
		},
		fundSummary: {
			section: "El Fondo. A vista de pájaro",

			subtitle1: "Principales magnitudes del Fondo",
			subtitle2: "Actividad de los fondos subyacentes",
			subtitle2Direct: "Resumen de actividad de las inversiones",
			subtitle3: "Evolución trimestral de la actividad de los fondos subyacentes",
			subtitle3Direct: "Inversiones - Evolución trimestral",
			subtitle4: "Resumen de la actividad de la cartera",

			subtitle1Box1Subtext1: "Tamaño",
			subtitle1Box1Subtext2: "Levantado",
			subtitle1Box2Subtext1: "Desembolsado",
			subtitle1Box2Subtext2: "Sobre tamaño",
			subtitle1Box3Subtext1: "Distribuido",
			subtitle1Box3Subtext2: "Sobre desembolsado",
			subtitle1Box4Subtext2: "TIR neta",
			
			subtitle2Box1Subtext1: "MOIC bruto",
			subtitle2Box2Subtext1: "Comprometidos",
			subtitle2Box2Subtext2: "Sobre tamaño",
			subtitle2Box3Subtext1: "Desembolsado",
			subtitle2Box3Subtext2: "Sobre compromiso",
			subtitle2Box4Subtext1: "Distribuido",
			subtitle2Box4Subtext2: "Sobre desembolsado",
			
			subtitle3Box1Subtext1: "TIR bruta",
			subtitle3Box1Subtext2: "MOIC bruto",
			subtitle3Box2Subtext1: "Nuevos compr.",
			subtitle3Box2Subtext2: "Sobre tamaño",
			subtitle3Box3Subtext1: "Desembolsado",
			subtitle3Box3Subtext2: "Sobre desembolsado",
			subtitle3Box4Subtext1: "Distribuido",
			subtitle3Box4Subtext2: "Sobre desembolsado",
			
			subtitle4Box1Header: "En el período",
			subtitle4Box1Subtext1: "GPs",
			subtitle4Box1Subtext2: "Coinv.",
			subtitle4Box1Subtext3: "Fondos",
			subtitle4Box2Subtext1: "Países (ventas) \n[04currencies] divisas / [04sectors] sectores",
			subtitle4Box2Subtext2: "Empresas \n[04regions] regiones / [04sectors] sectores",
			subtitle4Box3Subtext1: "Múltiplo medio de las empresas en cartera (MOIC promedio)",
			subtitle4Box4Subtext1: "Mediana del valor de las empresas en cartera",
		},
		coinvestSummary: {
			section: "Tabla resumen de coinversiones",
			operation: "Detalle de operaciones",
			manager: "Gestor",
			fund: "Inversión del Fondo", 
			date: "Fecha inversión", 
			exitDate: "Fecha venta",
			tvpi: "MOIC",
			currency: "Divisa",
			relevant: "Hechos relevantes del período",
			evolution: "Evolución de la actividad inversora",
			rpi: "RPI",
		},
		fundFigures: {
			section: "Principales magnitudes",

			fundFiguresUpdate: "Evolución de las principales magnitudes del Fondo",
			currentQuarter: "TRIMESTRE ACTUAL",
			previousQuarter: "TRIMESTRE ANTERIOR",
			variation: "VARIACIÓN",
			totalCommitment: "Total patrimonio comprometido",
			totalPaidCapital: "Desembolso de partícipes",
			cumulativeDistributions: "Distribuciones a partícipes",
			unfundedCommitment: "Compromiso pendiente de desembolso",
			totalCommitmentPortfolio: "Total comprometido en fondos",
			totalCommitmentCompaniesPortfolio: "Total comprometido en compañías",
			totalInvestedPortfolio: "Desembolso en fondos subyacentes",
			totalInvestedCompaniesPortfolio: "Desembolso en compañías subyacentes",
			cumulativeDistributionsPortfolio: "Distribuciones recibidas por el Fondo",
			totalFairValue: "Valoración de la cartera",
			netCashPosition: "Cuenta neta de la caja",
			totalNetAssetValue: "Valor total de la inversión (NAV)",
			grossMultipleCost: "Múltiplo bruto (MOIC) (3)",
			grossIRR: "TIR bruta (3)",
			distributionsPaidCapital: "Múltiplo de distribución (DPI)",
			residualValuePaidCapital: "Múltiplo de valor residual (RPI)",
			totalValuePaidCapital: "Múltiplo total para el inversor (TVPI)",
			netIRR: "TIR neta del inversor",
			paidCapitalToComCapital: "% Desembolso/Compromiso",
			percentageTotalCommitments: "Porcentaje calculado en base al total comprometido",
			percentageTotalPaidCapital: "Porcentaje calculado en base al total desembolsado",
			underlyingFundsNote: "Se corresponde con la TIR neta de las inversiones subyacentes",
			currentPeriod: "Período actual",
			previousPeriod: "Período anterior",
			investmentDivestment: "Inversiones / Desinversiones",
			activity: "Actividad del Fondo",
			ref1: "Porcentaje calculado en base al total comprometido",
			ref2: "Porcentaje calculado en base al total desembolsado",
			ref3: "Se corresponde con la TIR neta de las inversiones subyacentes"
		},
		fundEvolution: {			
			section: "Actividad del Fondo",
			totalCommitment: "Total patrimonio comprometido",
			totalPaidCapital: "Desembolso de partícipes",
			cumulativeDistributions: "Distribuciones a partícipes",
			totalCommitmentPortfolio: "Total comprometido en compañías",
			totalInvestedPortfolio: "Desembolso en compañías subyacentes",
			cumulativeDistributionsPortfolio: "Distribuciones recibidas por el Fondo",
			totalNetAssetValue: "Valor total de la inversión (NAV)",

			fundTitle: "Actividad del Fondo",
			portfolioSubtitle: "Cartera",
			valuationSubtitle: "Valoración",
			currentPeriod: "Período actual",
			previousPeriod: "Período anterior",

			navPrevQuarter: "Trimestre anterior",
			valuation: "Valoración",
			netCash: "Caja neta",
			newInvestment: "Nuevas inversiones a coste",
			distribution: "Distribuciones",
			totalNav: "Valor total de la inversión"

		},
		fundCharts: {			
			section: "Análisis de diversificación",		

			fundTitle: "Principales magnitudes del Fondo sobre compromiso",
			navLabel: "NAV",
			tvpiLabel: "TVPI",
			dpiLabel: "DPI",
			netTirLabel: "TIR neta",
			companiesLabel: "Compañías",
			gpsLabel: "Gestores",
			gpDiversificationSubtitle: "Diversificación por GP",
			regionDiversificationSubtitle: "Regiones",
			fxDiversificationSubtitle: "Divisas",
			sectorDiversificationSubtitle: "Sectores",
		},
		portfolioPerformance: {			
			section: "Rendimiento de la cartera",
			inM: "EN €M",
			investment: "INVERSIÓN",
			gp: "GESTOR",
			entryDate: "FECHA ENTRADA",
			initialInvestment: "COMPR. INICIAL",
			totalInvestment: "TOTAL INVERTIDO",
			realisedValue: "TOTAL REALIZADO",
			unrealisedValue: "VALOR NO REALIZADO",
			grossMoc: "MOIC BRUTO",
			fxMoc: "FX MOIC",
			currency: "DIVISA",
			note: "Nota:",
			noteContent: "Las valoraciones reportadas por Qualitas Funds se basan en las valoraciones reportadas por los gestores subyacentes.",
			portfolioPerformance: "Rendimiento de la cartera",
		},
		coinvestDetail: {
			section: "Coinversiones en detalle",
		
			coinvestWith: "Coinversión con [coinvestWith]",
			summary: "Resumen",
			entryDate: "Fecha entrada",
			initialCommitment: "Compromiso inicial",
			currency: "Divisa",
			participationPercentage: "Participación",
			country: "País",
			sector: "Sector",
			currentMoC: "MOIC actual",
			trimPerformance: "Rendimiento trim.",
			description: "Descripción",
			investmentThesis: "Tesis de inversión",
			sustainableDevelopmentGoals: "Objetivos de desarrollo sostenible",
			financialMetrics: "Métricas financieras",
			performanceEvolution: "Evolución del rendimiento ([quarter])",
			companyUpdate: "Actualización de la compañía",
			incomes: "Ingresos",
		},
		coinvestCover: {
			subtitle: "Compañias adquiridas recientemente con posterioridad a [quarter]",
			text: "Compañías sin actividad"
		},
		coinvestAcquisition: {
			section: "Compañías adquiridas después del trimestre",
			
			postQuarterAcquisition: "Compañías adquiridas con posterioridad al período reportado",
			coinvestWith: "Coinversión con [coinvestWith]",
			entryInvestment: "Inversión inicial",
			entryDate: "Fecha de inversión",
			country: "País",
			gp: "Gestor",
			sector: "Sector",
			description: "Descripción",
		},
		legalTerms: {
			section: "Descripción del Fondo/Términos legales",
			description: "Descripción del Fondo",
			fundStrategy: "Estrategia del Fondo",
			type: "Tipo",
			target: "Tamaño objetivo de los fondos Subyacente",
			diversificationF: "Diversificación (n de fondos)",
			diversificationC: "Diversificación (n de empresas) ",
			geography: "Geografía",
			geographyCont: "Geografía (Cont.)",
			stage: "Etapa",
			currency: "Divisa",
			strategy: "Estrategia",

			fees: "Comisiones de Gestión",
			duringPeriod: "Durante el período de inversión",
			afterPeriod: "Después del período de inversión",
			carried: "Carried Interest",

			overview: "Visión general del fondo",
			fullName: "Nombre completo",
			isin: "ISIN",
			firstDate: "Fecha primer cierre",
			finalDate: "Fecha último cierre",
			vintage: "Añada",
			firstCommitment: "Fecha primera inversión",
			term12: "Duración de 10 (diez) años desde",
			termExt: "Extensión potencial",
			investmentPeriod: "Período de inversión",
			investmentPeriodExt: "Prórroga del período de inversión",
			fundCurrency: "Divisa del fondo",
			totalCurrentCommitments: "Compromiso total actual",
			domicile: "Domicilio",
			legalForm: "Forma jurídica",
			generalPartner: "Partner general",
			maxSingleInvest: "Inversión única máxima",
			oversubscription: "Sobresuscripción",

			directive: "Directiva de inversión alternativa (AIFMD)",
			regulators: "Reguladores",
			depositary: "Depositario",
			registration: "País de registro",
			riskManager: "Gerente de Riesgos externo",
			auditor: "Auditor",
			lawyer: "Abogado",

			committees: "Comités",
			invComm: "Comité inversor",
			supervisory: "Comité supervisor",
			keyMen: "Responsables",
			note: "*Nota: Este informe muestra de forma conjunta las cifras relativas a los vehículos FCR y SCR."
		},
		glossary: {
			section: "Glosario de términos",
			term1: "Apalancamiento",
			definition1: "Nivel de endeudamiento de una inversión, generalmente medido como un múltiplo sobre EBITDA (Deuda / EBITDA).",
			term2: "Añada (“Vintage”)",
			definition2: "Año en que se lleva a cabo la primera inversión de un fondo.",
			term3: "Buyout",
			definition3: "Operación de adquisición de las acciones de una empresa, cuyo precio se financia parcialmente con deuda. El objetivo es que dicha deuda se amortice con el cash flow generado en el futuro por la propia empresa, aumentando así la rentabilidad sobre la parte del precio financiada con capital.",
			term4: "Capital Call (Llamada de Capital)",
			term4Audience1: "Al fondo de fondos (“FdFs”)",
			definition4Audience1: "Solicitud de desembolso al FdFs como parte del Compromiso de Inversión adquirido en cada uno de los fondos subyacentes de la cartera. Con el propósito de o bien hacer frente a pagos por la adquisición de inversiones o bien abonar gastos/comisiones del fondo subyacente.",
			term4Audience2: "A los partícipes",
			definition4Audience2: "Solicitud de desembolso a los inversores como parte del Compromiso de Inversión adquirido en su fecha de entrada en el FdFs. Su propósito es o bien hacer frente a pagos de capital calls emitidos por los subyacente o bien abonar gastos/comisiones del FdFs.",
			term5: "Carried Interest",
			definition5: "Término referido a la Comisión de Éxito. Es la Remuneración variable a favor de las sociedades gestoras o sus empleados (tanto de los fondos subyacentes como del fondo de fondos en cuestión), calculada en función de las plusvalías obtenidas por los inversores. Generalmente se devenga a partir de una tasa de rentabilidad preferente para el inversor (ver hurdle rate).",
			term6: "Cash Flow",
			definition6: "Flujo de Caja, desembolsos y distribuciones.",
		  	term7: "CIF",
			definition7: "Código de Identificación Fiscal.",
			term8: "CNMV",
			definition8: "Comisión Nacional del Mercado de Valores.",
			term9: "Coinversión",
			definition9: "Inversión por parte del FdFs en un activo o empresa específico de manera directa y de la mano de una sociedad gestora de reconocido prestigio que lidere la inversión por cuenta del grupo de coinversores.",
			term10: "Compromiso de Inversión",
			definition10: "Importe máximo al que un inversor en un fondo o FdFs se compromete a desembolsar. Dicho compromiso de inversión será solicitado a lo largo del tiempo a través de Capital Calls.",
		},
		portfolioInvestments: {
			section: "Detalle de las inversiones en cartera",
			underlyingCompanies: "Fondos subyacentes",
			coinvestments: "Coinversiones",
			composition: "Composición de la cartera",
			investments: "Inversiones",
			primaryFunds: "Fondos primarios",
			coinvestmentsAndSecondaries: "Coinversiones y secundarios",
			diversification: "Diversificación de la cartera",
			diversificationCurrency: "Diversificación por divisa",

			investmentFund: "FONDOS SUBYACENTES",
			nInvestments: "N. DE INVERSIONES",
			country: "PAÍS",
			type: "TIPO DE INVERSIÓN",
			commitment: "COMPROMISO",
			drawdown: "DESEMBOLSO",
			coinvestmentAndSecondaries: "COINVERSIONES Y SECUNDARIOS"
		},
		underlyingFunds: {
			section: "Fondos subyacentes y coinversiones",
			underlying: "FONDOS SUBYACENTES", 
			netIrr: "TIR NETA",	
			coinvestmentAndSecondaries: "COINVERSIONES Y SECUNDARIOS",
			currentVsPrev: "Período actual vs. anterior",
			distributions: "Distribuciones",
			residual: "Valor residual",
			totalValue: "VALOR TOTAL",
			relativeValue: "VALOR RELATIVO",
			absoluteValue: "VALOR ABSOLUTO",
			rpi: "RPI"
		},
		coinvestTable:{
			section: "Resumen coinversiones",
			nInvestments: "N. de inversiones",
			totalInvested: "Total invertido",
			avg: "medio",
			countriesSectors: "Países / sectores",
			geographicFocus: "Enfoque geográfico",
			evolution: "Evolución de la inversión",
			evolucionOfLastQuarter: "Evolución de la actividad inversora durante el último trimestre",
			
			state: "ESTADO",
			name: "NOMBRE",
			fund: "FONDO",
			country: "PAÍS Y SECTOR",
			ev: "EV",
			dateInv: "FECHA INV.",
			holdingPeriod: "PERÍODO TENENCIA",
			investment: "INVERSIÓN",
			opinion: "OPINIÓN GESTOR",
			ref1: "*NI = nueva inversión | C = En cartera | NR = Nueva realización | R = Realizada",
			ref2: "**Distribuciones",
			ref3: "Valor residual",
			option1: "Muy favorable",
			option2: "En plan",
			option3: "Watchlist",
			option4: "En riesgo",
			years: "años",
			rpi: "RPI"
		},
		portfolioDiversification: {
			section: "Análisis de empresas en cartera",
			companies: 'Empresas',
			countries: 'Países',
			sectors: 'Sectores',
			period: "Durante el período",
			portfolioDiversificationAnalysis: 'Análisis de empresas en cartera',
			exposureAnalysis: 'Análisis de exposición',
			information: "Información & tecnología",
			health: 'Salud',
			consumerGoods: 'Consumo',
			industrials: 'Industria & Materiales',
			financials: "Finanzas",
			communication: "Comunicación",
			nordic: "PAÍSES NÓRDICOS",
			uk: "REINO UNIDO E IRLANDA",
			france: "FRANCIA",
			italy: "ITALIA",
			usa: "ESTADOS UNIDOS",
			benelux: "BENELUX",

			diversification: "Análisis de diversificación",
			investmentYear: "Año de inversión",
			regions: "Regiones",
			enterpriseValue: "Tamaño de compañía (EV)"
		},
		newInvestments: {
			section: "Nuevas inversiones y desinversiones",
			period: "Durante el período",
			investments: "Inversiones",
			newInvestments: "Nuevas inversiones",
			newRealizations: "Nuevas desinversiones",
			distributions: "Distribuciones",
			realizations: "Desinversiones",
			companies: "compañías",
			previous: "Desde el inicio",
			lastQuarter: "Último trimestre",
			fund: "FONDO",
			name: "NOMBRE",
			countryAndActivity: "PAÍS Y SECTOR",
			entry: "COMPRA",
			investment: "INVERSIÓN",
			noRealizations: "No hay nuevas ventas en este período",
			noInvestments: "No hay nuevas inversiones en este período",
			ref1: "**Distribuciones",
			ref2: "Valor residual",
		},
		portfolioBreakdown : {
			section: "Desglose completo de la cartera",
			state: "ESTADO",
			name: "NOMBRE",
			fund: "FONDO",
			country: "PAÍS Y SECTOR",
			ev: "EV",
			dateInv: "FECHA INV.",
			holdingPeriod: "PERÍODO TENENCIA",
			investment: "INVERSIÓN",
			opinion: "OPINIÓN GESTOR",
			ref1: "*NI = nueva inversión | C = En cartera | NR = Nueva realización | R = Realizada",
			ref2: "**Distribuciones",
			ref3: "Valor residual",
			option1: "Muy favorable",
			option2: "En plan",
			option3: "Watchlist",
			option4: "En riesgo",
			years: "años",
		},
		companiesList: {
			section: "Detalle de empresas en cartera",
			underlying: "Fondo subyacente",
			date: "Fecha compra", 
			exitDate: "Fecha venta",
			stage: "Estado",
			relevant: "Hechos relevantes del período",
			evolution: "Evolución de la actividad inversora",
			fundInvestment: "Inversión del Fondo",
		},
		newFunds: {
			section: "Nuevos Fondos en cartera",
			name: "Nombre",
			country: "País",
			fund: "Fondo",
			size: "Tamaño",
			commitment: "Compromiso",
			investmentDate: "Fecha inversión",
			type: "Tipo",
			target: "Región",
			strategy: "Estrategia",
		}
	
	},
	en: {
		cover: {
			coverSubtitle: "Experts in the European lower mid-market",
			coverTitle: "QEP Mutual Private Equity",
			coverDate: "Quarterly Report ",
			coverConfidential: "Private & Confidential",
			march: "March",
			june: "June",
			september: "September",
			december: "December",
			confidential: "CONFIDENTIAL"
		},
		index:{
			section: "Index",

			content: "Contents",
			theFund: "The Fund. At a glance",
			mainFigures: "Main figures",
			fundFiguresUpdate: "Fund figures update",
			investmentDivestment: "Investment / Realizations",
			portfolioInvestmentsInDetail: "Portfolio investments in detail",
			compositionOfPortfolio: "Composition of the portfolio of the Fund",
			underlyingFundsCoinvestments: "Underlying funds and co-investments",
			portfolioCompaniesInDetail: "Detail of portfolio companies",
			portfolioDiversificationAnalysis: "Portfolio diversification analysis",
			newInvestmentsRealizations: "New investments and realizations",
			portfolioFundsInDetail: "Detail of underlying funds",
			newFunds: "New underlying funds",
			coInvestmentsSummary: "Co-investments summary",
			coInvestmentsSummaryTable: "Co-investments summary table",
			coInvestmentsInDetail: "Detail of co-investments",
			fullPortfolioBreakdown: "Full portfolio breakdown",
			alphabeticalListing: "Alphabetical listing of portfolio companies",
			fundDescriptionLegalTerms: "Fund description / Legal terms",
			termsGlossary: "Glossary",
			activityOfTheFund: "Activity of the Fund",
			portfolio: "Portfolio",
			valuation: "Valuation",
			diversificationAnalysis: "Diversification analysis",
			mainKpis: "Main portfolio KPIs over commitment",
			portfolioPerformance: "Portfolio performance",
			portfolioCompanies: "Portfolio companies",
			recentPortfolioCompanies: "Recent portfolio companies",
		},
		fundSummary: {
			  section: "The Fund. At a glance",
			  subtitle1: "Fund overall figures",
			  subtitle2: "Underlying funds activity",
			  subtitle3: "Underlying funds - quarterly update",
			  subtitle4: "Portfolio companies activity summary",

			  subtitle2Direct: "Investment summary",
			  subtitle3Direct: "Investment - quaterly update",

			  subtitle1Box1Subtext1: "Fund size",
			  subtitle1Box1Subtext2: "Raised",
			  subtitle1Box2Subtext1: "Drawdown",
			  subtitle1Box2Subtext2: "Over fund size",
			  subtitle1Box3Subtext1: "Distributed",
			  subtitle1Box3Subtext2: "Over drawdown",
			  subtitle1Box4Subtext2: "Net IRR",

			  subtitle2Box1Subtext1: "Gross MOIC",
			  subtitle2Box2Subtext1: "Committed",
			  subtitle2Box2Subtext2: "Over fund size",
			  subtitle2Box3Subtext1: "Drawdown",
			  subtitle2Box3Subtext2: "Over committed",
			  subtitle2Box4Subtext1: "Distributed",
			  subtitle2Box4Subtext2: "Over drawdown",

			  subtitle3Box1Subtext1: "Gross IRR",
			  subtitle3Box1Subtext2: "Gross MOIC",
			  subtitle3Box2Subtext1: "New commitments",
			  subtitle3Box2Subtext2: "Over fund size",
			  subtitle3Box3Subtext1: "Drawdown",
			  subtitle3Box3Subtext2: "Over fund size",
			  subtitle3Box4Subtext1: "Distributed",
			  subtitle3Box4Subtext2: "Over drawdown",
			  
			  subtitle4Box1Header: "In the quarter",
			  subtitle4Box1Subtext1: "GPs",
			  subtitle4Box1Subtext2: "Co-inv.",
			  subtitle4Box1Subtext3: "Funds",
			  subtitle4Box2Subtext1: "Countries(Sales) \n[04currencies] Currencies / [04sectors] Sectors",
			  subtitle4Box2Subtext2: "Companies \n[04regions] regions / [04sectors] sectors", 
			  subtitle4Box3Subtext1: "Average MOIC",
			  subtitle4Box4Subtext1: "Median EV of portfolio \ncompanies"
		}
		,
		fundFigures: {
			section: "Main figures",

			fundFiguresUpdate: "Main figures evolution of the Fund",
			currentQuarter: "CURRENT QUARTER",
			previousQuarter: "PREVIOUS QUARTER",
			variation: "VARIATION",
			totalCommitment: "Total Fund size",
			totalPaidCapital: "Capital called",
			cumulativeDistributions: "Cumulative distributions to investors",
			unfundedCommitment: "Unfunded commitment fordrawdown",
			totalCommitmentPortfolio: "Total committed in underlying funds",
			totalCommitmentCompaniesPortfolio: "Total committed in portfolio companies",
			totalInvestedPortfolio: "Total invested in underlying funds",
			totalInvestedCompaniesPortfolio: "Total invested in underlying companies",
			cumulativeDistributionsPortfolio: "Cumulative distributions",
			totalFairValue: "Total fair value of the current companies",
			netCashPosition: "Net cash position",
			totalNetAssetValue: "Total net asset value (NAV)",
			grossMultipleCost: "Gross multiple to cost (MOIC) (3)",
			grossIRR: "Gross IRR (3)",
			distributionsPaidCapital: "Distributions to paid-in capital (DPI)",
			residualValuePaidCapital: "Residual value to paid-in capital (RVPI)",
			totalValuePaidCapital: "Total value to paid-in capital (TVPI)",
			netIRR: "Net IRR",
			paidCapitalToComCapital: "% Capital called / fund size",
			percentageTotalCommitments: "Percentage based on total commitments",
			percentageTotalPaidCapital: "Percentage based on total paid-in capital",
			underlyingFundsNote: "Corresponds to TVPI and net IRR of underlying funds and co-investments",
			currentPeriod: "Current period",
			previousPeriod: "Previous period",
			investmentDivestment: "Investment / Realizations",
			activity: "Fund activity",
			ref1: "Percentage based on total commitments",
			ref2: "Percentage based on total paid-in capital",
			ref3: "Corresponds to net IRR of underlying funds and co-investments"
		},
		fundEvolution: {
			section: "Activity of the Fund",

			totalCommitment: "Total Fund size",
			totalPaidCapital: "Capital called",
			cumulativeDistributions: "Cumulative distributions to investors",
			totalCommitmentPortfolio: "Total commitment in portfolio companies",
			totalInvestedPortfolio: "Total invested in portfolio companies",
			cumulativeDistributionsPortfolio: "Cumulative distributions",
			totalNetAssetValue: "Total net asset value (NAV)",

			fundTitle: "Fund activity",
    		portfolioSubtitle: "Portfolio",
    		valuationSubtitle: "Valuation",
			currentPeriod: "Current period",
			previousPeriod: "Previous period",
			
			navPrevQuarter: "NAV. Prev. quarter",
			valuation: "Valuation",
			netCash: "Net cash position",
			newInvestment: "New investments",
			distribution: "Distributions",
			totalNav: "Current NAV"
		},
		fundCharts: {
			section: "Diversification analysis",

			fundTitle: "Main portfolio KPIs over commitment",
			navLabel: "NAV",
			tvpiLabel: "TVPI",
			dpiLabel: "DPI",
			netTirLabel: "NET IRR",
			companiesLabel: "Companies",
			gpsLabel: "GPs",
			gpDiversificationSubtitle: "GP Diversification",
			regionDiversificationSubtitle: "Regions",
			fxDiversificationSubtitle: "Currencies",
			sectorDiversificationSubtitle: "Sectors",
		},
		portfolioPerformance: {
			section: "Portfolio performance",
			inM: "IN €M",
			investment: "INVESTMENT",
			gp: "GP",
			entryDate: "ENTRY DATE",
			initialInvestment: "INITIAL INVEST.",
			totalInvestment: "TOTAL INVEST.",
			realisedValue: "REALISED VALUE",
			unrealisedValue: "UNREALISED VALUE",
			grossMoc: "GROSS MOIC",
			fxMoc: "FX MOIC",
			currency: "CURRENCY",
			note: "Note:",
			noteContent: "Valuations reported by Qualitas Funds are based on valuations provided by the GPs/Sponsors.",
			portfolioPerformance: "Portfolio performance",
		},
		coinvestDetail: {
			section: "Portfolio companies",
			
			coinvestWith: "[coinvestWith] co-investment",
			summary: "Overview",
			entryDate: "Entry date",
			initialCommitment: "Entry investment",
			currency: "Currency",
			participationPercentage: "Ownership",
			country: "Country",
			sector: "Sector",
			currentMoC: "Current MOIC",
			trimPerformance: "Quarter value devlp.",
			description: "Description",
			investmentThesis: "Investment rationale",
			sustainableDevelopmentGoals: "ESG objectives and developments",
			financialMetrics: "Financials",
			performanceEvolution: "Trading performance (as of [quarter])",
			companyUpdate: "Major developments during the quarter",
			incomes: "Revenues"
		},
		coinvestCover: {
			subtitle: "Recent portfolio companies acquired post [quarter]",
			text: "Companies without activity"
		},
		coinvestAcquisition: {
			section: "Recent portfolio companies",
			
			postQuarterAcquisition: "Recent portfolio companies acquired post quarter",
			coinvestWith: "[coinvestWith] co-investment",
			entryInvestment: "Entry investment",
			entryDate: "Entry date",
			country: "Country",
			gp: "GP",
			sector: "Sector",
			description: "Description",
		},
		portfolioFunds: {
			section: "Portfolio funds in detail",
			summary: "Investment summary",
			size: "Fund size (M)",
			commitment: "Commitment (M)",
			origin: "Origin",
			target: "Target region",
			date: "Inv. date",
			strategy: "Strategy",
			type: "Fund type",
			sector: "Sector",
			region: "Region",
			fundPerc: "Fund",
			
			totalCommitment: "Total commitment",
			totalDrawdowns:  "Total invested",
			pendingDrawdowns: "Pending drawdowns",
			otherAdjustments: "Other adjustments",
			totalValue: "Total value (TVPI)",
			nav: "NAV + Distributions",
			distributions: "Distributions (DPI)",
			residual: "Residual Value (RVPI)",
			
			activity: "Fund activity",
			evolution: "Quarterly evolution",
			drawdownVsCommitment: "Drawdown vs. commitment",
			current: "Current period",
			previous: "Previous period",
			pending: "Pending",
			drawdownVsDistributions: "Drawdown vs. distributions",
			drawdown: "Drawdown",
			distribution: "Distributions",
			tvpi: "TVPI",
			currentVsPrev: "Current vs. previous quarter",
			remarkable: "Remarkable developments during the period",
			
			analysis: "Portfolio analysis",
			state: "STATUS",
			fund: "FUND",
			name: "NAME",
			country: "COUNTRY & SECTOR",
			ev: "EV",
			dateInv: "ENTRY DATE",
			holdingPeriod: "HOLDING PERIOD",
			investment: "INVESTMENT",
			opinion: "MANAGER'S OPINION",
			ref1: "* NI = New Investment | C = In portfolio | NR = New Realized | R = Realized",
			ref2: "**Distributions",
			ref3: "Residual value",
			option1: "Strong positive",
			option2: "On plan",
			option3: "Watchlist",
			option4: "At risk",
			years: "years",
			rpi: "RVPI"

		},
		coinvestSummary: {
			section: "Co-investments summary",
			operation: "Details of the transactions",
			manager: "Manager",
			fund: "Fund investment", 
			date: "Entry date", 
			exitDate: "Exit date",
			tvpi: "TVPI",
			currency: "Currency",
			relevant: "Relevant events of the period",
			evolution: "Investment activity evolution",
			rpi: "RVPI"
		},
		legalTerms: {
			section: "Fund description / Legal terms",
			description: "Fund description",
			fundStrategy: "Fund strategy",
			type: "Type",
			target: "Target Size of Underlying funds",
			diversificationF: "Diversification (n of funds)",
			diversificationC: "Diversification (n of companies)",
			geography: "Geography",
			geographyCont: "Geography (Cont.)",
			stage: "Stage",
			currency: "Currency",
			strategy: "Strategy",

			fees: "Management fees",
			duringPeriod: "During the Investment period",
			afterPeriod: "After the Investment period",
			carried: "Carried Interest",

			overview: "Fund overview",
			fullName: "Full name",
			isin: "ISIN",
			firstDate: "First closing date",
			finalDate: "Final closing date",
			vintage: "Vintage year",
			firstCommitment: "First commitment date",
			term12: "Term 10 (ten) years from",
			termExt: "Term extension",
			investmentPeriod: "Investment period",
			investmentPeriodExt: "Investment period extension",
			fundCurrency: "Fund currency",
			totalCurrentCommitments: "Total current commitments",
			domicile: "Domicile",
			legalForm: "Legal Form",
			generalPartner: "General partner",
			maxSingleInvest: "Maximum single Investment",
			oversubscription: "Oversubscription",

			directive: "Alternative Investment Directive (AIFMD)",
			regulators: "Regulators",
			depositary: "Depositary",
			registration: "Registration Country",
			riskManager: "External Risk Manager",
			auditor: "Auditor",
			lawyer: "Lawyer",

			committees: "Committees",
			invComm: "Investment Committee",
			supervisory: "Supervisory Committee",
			keyMen: "Key Men",
			note: "*Note: This report summarizes the performance of the two investment vehicles together, FCR and SCR."
		},
		glossary: {
			section: "Glossary",

			term1: "Buyout",
			definition1: "Acquisition of a company’s shares with partial debt financing. By amortizing debt with the company’s future cashflows, one can increase the profitability of equity investments.",
			term2: "CAGR",
			definition2: "Compound Annual Growth Rate",
			term3: "Capital Call",
			term3Audience1: "To the funds of funds (“FoFs”)",
			definition3Audience1: "Drawdown request to the FoFs as part of the Investment Commitment for each of the underlying funds of the portfolio. Used for the acquisition of investments and for the payment of fees/expenses.",
			term3Audience2: "To investors",
			definition3Audience2: "Drawdown request to investors as part of the Investment Commitment for entry into the FoFs. Used for the payment of capital calls issued by underlying funds and for expenses/commissions of the FoFs.",
			term4: "Carried Interest",
			definition4: "Refers to the Exit Bonus. It is the variable compensation of management companies and their employees (both of the underlying funds and of the FoFs in question), calculated according to the capital gains obtained by the investors. Generally, it accrues from a preferential rate of return for the investor (see Hurdle Rate).",
			term5: "Cash Flow",
			definition5: "Disbursements and distributions",
			term6: "CIF",
			definition6: "Tax identification code.",
			term7: "CNMV",
			definition7: "Spanish securities exchange commission (Comisión Nacional Mercado de Valores).",
			term8: "Co-investment",
			definition8: "Direct investment by the FoFs in a specific asset or company. Led by a recognized management company on behalf of the group of co-investors.",
			term9: "Commitment date",
			definition9: "Date when Investment Commitment is finalized.",
			term10: "Constitution date",
			definition11: "Date when the fund is registered with the applicable regulator.",
		},
		portfolioInvestments: {
			section: "Portfolio investments in detail",
			underlyingCompanies: "Underlying funds",
			coinvestments: "Co-investments",
			composition: "Composition of the Fund's portfolio",
			investments: "Investments",
			primaryFunds: "Primary funds",
			coinvestmentsAndSecondaries: "Co-investments and secondaries",
			diversification: "Portfolio diversification",
			diversificationCurrency: "Currency diversification",

			investmentFund: "INVESTMENT FUND",
			nInvestments: "N. INVESTMENTS",
			country: "COUNTRY",
			type: "INVESTMENT TYPE",
			commitment: "COMMITMENT",
			drawdown: "DRAWDOWN",
			coinvestmentAndSecondaries: "CO-INVESTMENTS AND SECONDARIES",
		},
		underlyingFunds: {
			section: "Underlying funds and co-investments",
			underlying: "UNDERLYING FUNDS", 
			netIrr: "NET IRR",
			coinvestmentAndSecondaries: "CO-INVESTMENTS AND SECONDARIES",
			currentVsPrev: "Current quarter vs. previous quarter",
			distributions: "Distributions",
			residual: "Residual value",
			totalValue: "TOTAL VALUE",
			relativeValue: "RELATIVE VALUE",
			absoluteValue: "ABSOLUTE VALUE",
			rpi: "RVPI"
		},
		coinvestTable:{
			section: "Co-investments summary",
			nInvestments: "N. of investments",
			totalInvested: "Total invested",
			avg: "avg",
			countriesSectors: "Countries / Sectors",
			geographicFocus: "Geographic focus",
			evolution: "Evolution of the investments",
			evolucionOfLastQuarter: "Evolution of investor activity during the last quarter",

			state: "STATUS",
			name: "NAME",
			fund: "FUND",
			country: "COUNTRY & SECTOR",
			ev: "EV",
			dateInv: "ENTRY DATE",
			holdingPeriod: "HOLDING PERIOD",
			investment: "INVESTMENT",
			opinion: "MANAGER'S OPINION",
			ref1: "* NI = New Investment | C = In portfolio | NR = New Realized | R = Realized",
			ref2: "**Distributions",
			ref3: "Residual value",
			option1: "Strong positive",
			option2: "On plan",
			option3: "Watchlist",
			option4: "At risk",
			years: "years",
			rpi: "RVPI"
		},
		portfolioDiversification: {
			section: "Portfolio diversification analysis",
			companies: 'Companies',
			countries: 'Countries',
			sectors: 'Sectors',
			period: "During the quarter",
			portfolioDiversificationAnalysis: 'Portfolio diversification analysis',
			exposureAnalysis: 'Exposure analysis',
			information: "Information technologies",
			health: 'Health care',
			consumerGoods: 'Consumer goods',
			industrials: 'Industrials & materials',
			financials: "Financials",
			communication: "Communication services",
			nordic: "NORDIC COUNTRIES",
			uk: "UK & IRELAND",
			france: "FRANCE",
			italy: "ITALY",
			usa: "USA",
			benelux: "BENELUX",

			diversification: "Diversification analysis",
			investmentYear: "Investment year",
			regions: "Regions",
			enterpriseValue: "Enterprise value"
		},
		newInvestments: {
			section: "New investments and realizations",
			period: "During the quarter",
			investments: "Investments",
			newInvestments: "New investments",
			newRealizations: "New realizations",
			distributions: "Distributions",
			realizations: "Realizations",
			companies: "companies",
			previous: "Previous",
			lastQuarter: "Last quarter",
			fund: "FUND",
			name: "NAME",
			countryAndActivity: "COUNTRY & ACTIVITY",
			entr: "ENTRY",
			investment: "INVESTMENT",
			noRealizations: "There is no new realization within this period",
			noInvestments: "There is no new investment within this period",
			ref1: "**Distributions",
			ref2: "Residual value",
		},
		portfolioBreakdown : {
			section: "Full portfolio breakdown",
			state: "STATUS",
			name: "NAME",
			fund: "FUND",
			country: "COUNTRY & SECTOR",
			ev: "EV",
			dateInv: "ENTRY DATE",
			holdingPeriod: "HOLDING PERIOD",
			investment: "INVESTMENT",
			opinion: "MANAGER'S OPINION",
			ref1: "* NI = New Investment | C = In portfolio | NR = New Realized | R = Realized",
			ref2: "**Distributions",
			ref3: "Residual value",
			option1: "Strong positive",
			option2: "On plan",
			option3: "Watchlist",
			option4: "At risk",
			years: "years",
		},
		companiesList: {
			section: "Detail of portfolio companies",
			underlying: "Underlying fund",
			date: "Entry date", 
			exitDate: "Exit date",
			stage: "Status",
			relevant: "Relevant events of the period",
			evolution: "Evolution of Investment activity",
			fundInvestment: "Fund investment",
		},
		newFunds: {
			section: "New portfolio funds",
			name: "Name",
			country: "Country",
			fund: "Fund",
			size: "Size",
			commitment: "Commitment",
			investmentDate: "Investment date",
			type: "Type",
			target: "Region",
			strategy: "Strategy",
		}

	},
};

export { languages };