import { useEffect, useState } from "react";

import { IUseForm } from "./IUseForm";

const useForm = ({ api }: IUseForm) => {
	let emptyPageNumbers = {
		section1Pages: 0,
		section2Pages: 0,
		section3Pages: 0,
		section4Pages: 0,
		section5Pages: 0,
		section6Pages: 0,
		section7Pages: 0,
		section8Pages: 0,
		section9Pages: 0,
		section10Pages: 0,
		section11Pages: 0,
		section12Pages: 0,
		section13Pages: 0,
	}
	const [loadingFunds, setLoadingFunds] = useState<boolean>(false);
	const [loadingData, setLoadingData] = useState<boolean>(false);
	const [qualitasFunds, setQualitasFunds] = useState<any[]>([]);
	const [sections, setSections] = useState<any[]>([]);
	const [funds, setFunds] = useState<any[]>([]);
	const [selectedQualitasFund, setSelectedQualitasFund] = useState<number | undefined>(undefined);
	const [selectedSections, setSelectedSections] = useState<any[]>([]);
	const [selectedFunds, setSelectedFunds] = useState<any[]>([]);
	const [year, setYear] = useState<string>(new Date().getFullYear().toString());
	const [selectedQuarter, setSelectedQuarter] = useState<string>("03-31");
	const [selectedLanguage, setSelectedLanguage] = useState<string>('es');
	const [reportData, setReportData] = useState<any>();
	const [isFoF, setIsFoF] = useState<boolean>(false)
	const [reportName, setReportName] = useState<string>('Report')
	const [pageNumbers, setPageNumbers] = useState<any>(emptyPageNumbers)

	useEffect(() => {
		getPeips();
		getFunds();
	}, []);

	const getPeips = () => (
		api.getPeips().then((qualitasfunds: string[]) => {
			const qualitasFundsOptions = qualitasfunds.map((qualitasfund: any, index: number) => ({
				value: qualitasfund.idPeip,
				label: qualitasfund.peipNameLong
			}));
			setQualitasFunds(qualitasFundsOptions);
		})
	);

	const getSections = (isFoF: boolean) => (
		api.getSections(isFoF).then((sections: any[]) => {
			const sectionsOptions = sections.map((section: any, index: number) => ({
				value: (index + 1).toString(),
				label: section.label,
				sectionId: section.id
			}));
			setSections([
				{
					value: 0,
					label: 'All selected'
				},
				...sectionsOptions
			]);
		})
	);

	const getFunds = () => {
		setLoadingFunds(true);
		api.getDirectFunds().then((funds: string[]) => {
			const fundsOptions = funds.map((fund: any, index: number) => ({
				value: fund.idFund,
				label: fund.fundNameLong
			}));
			setFunds([
				{
					value: 0,
					label: 'All selected'
				},
				...fundsOptions
			]);
		}).finally(() => setLoadingFunds(false))
	};

	useEffect(() => {
		setSelectedFunds(funds);
	}, [funds]);

	useEffect(() => {
		setSelectedSections(sections);
	}, [sections]);

	const handleMultiselectChange = (selectedOptions: any[], options: any[], setter: (values: any[]) => void) => (_: string[], valuesObj: any[]) => {
		const allOptionSelected = valuesObj[0].value === 0;
		const wasAllOptionSelected = selectedOptions.filter((selectedOption: any) => selectedOption.value === 0);
		if (allOptionSelected && (valuesObj.length === options.length)) {
			setter(valuesObj);
		} else if (allOptionSelected && (valuesObj.length !== options.length)) {
			if (wasAllOptionSelected.length > 0) {
				setter(valuesObj.slice(1));
			} else {
				setter(options);
			}
		} else {
			if (wasAllOptionSelected.length > 0) {
				setter([]);
			} else if (valuesObj.length === options.length - 1) {
				setter(options);
			} else {
				setter(valuesObj);
			}
		}
	};


	const handleChange = (type: string) => (value: any) => {
		switch (type) {
			case 'Qualitas Fund': {
				setSelectedSections([])
				const isPeip = !qualitasFunds.find((x:any)=> x.value === value).label.includes('Direct')
				getSections(isPeip)
				setIsFoF(isPeip)
				setSelectedQualitasFund(value); 
				break;
			} 
			case 'Year': setYear(value); break;
			case 'Quarter': setSelectedQuarter(value); break;
			case 'Language': setSelectedLanguage(value); break;

			default: break;
		}
	};

	const handleChangeSelectedSections = (newSelection: any[]) => setSelectedSections(newSelection);
	const handleChangeSelectedFunds = (newSelection: any[]) => setSelectedFunds(newSelection);
	const sendData = () => {
		let peipName = qualitasFunds.find((peip: any) => peip.value === selectedQualitasFund) 
		let quarters: {[key: string]: string} = {'03-31': 'Q1', '06-30': 'Q2', '09-30': 'Q3', '12-31': 'Q4'}
		setReportName(`${year}${quarters[selectedQuarter]}_Report_${peipName ? peipName.label : 'Qualitas_Funds'}_${selectedLanguage.toUpperCase()}`)
		setReportData({})
		setPageNumbers(emptyPageNumbers)
		
		let sendObj = {
			peipId: selectedQualitasFund !== undefined ? selectedQualitasFund : 0,
			fundId: selectedFunds.map((fund: any) => fund.value),
			date: `${year}-${selectedQuarter}`,
			language: selectedLanguage
		}

		setLoadingData(true); 
 		api.send(sendObj, isFoF).then((data: any) => {
			setReportData(data.data);
			isFoF ? assignFoFPageNumbers(data.data) : asignDirectPageNumbers(data.data)
		}).finally(() => 
			setLoadingData(false)
		)
 	};

	 const calculateBreakdownPages = (portfolioBreakdown: any[], rowsPerPage: number) => {
		let pages = [];
		let currentPage: any = [];
		let currentRowCount = 0;
	  
		portfolioBreakdown.forEach((fund: any) => {
		  let fundRows = fund.companies.length + 2; // +1 for the title +1 for the header
		  if (currentRowCount + fundRows <= rowsPerPage) {
			currentPage.push({ fundName: fund.fund, rows: fund.companies });
			currentRowCount += fundRows;
		  } else {
			// Add remaining rows in the current page
			let remainingRows = rowsPerPage - currentRowCount;
			let remainingCompanies = fund.companies.slice(
			  0,
			  remainingRows - 1 < 0 ? 0 : remainingRows - 1
			);
	  
			if (remainingCompanies.length > 0) {
			  currentPage.push({ fundName: fund.fund, rows: remainingCompanies });
			}
	  
			pages.push(currentPage);
			currentPage = [];
			currentRowCount = 0;
	  
			// Continue adding the remaining rows to the next page
			let remainingFundRows = fund.companies.slice(
			  remainingRows - 1 < 0 ? 0 : remainingRows - 1
			);
			while (remainingFundRows.length > 0) {
			  let rowsForNextPage = remainingFundRows.slice(0, rowsPerPage);
			  remainingFundRows = remainingFundRows.slice(rowsPerPage);
			  currentPage.push({ fundName: fund.fund, rows: rowsForNextPage });
	  
			  if (remainingFundRows.length > 0) {
				pages.push(currentPage);
				currentPage = [];
			  } else {
				currentRowCount += rowsForNextPage.length + 2;
				break;
			  }
			}
		  }
		});
	  
		if (currentPage.length > 0) {
		  pages.push(currentPage);
		}
		return pages.length;
	};
	  
	const assignFoFPageNumbers = (data: any) => {
		let pageNumbers: any = {}

		let portfolioSum = 0;
		data.portfolioFunds.map((fund: any) => {
			portfolioSum = portfolioSum + 2 + (fund.portfolioAnalysis.length > 19 ? 1 : 0)
		});

		let breakdownLength = calculateBreakdownPages(data.portfolioBreakdown, 19)
		console.log(breakdownLength);
	
		let section1Pages = 1;
		let section2Pages = data.fundFigures ? 1 : 0;
		let section3Pages = data.portfolioInvestments ? 
			((data.portfolioInvestments.investmentData.length + data.portfolioInvestments.coinvestmentData.length) > 26 ? 2 : 1) : 0;
		let section4Pages = data.underlyingFunds ? 
			((data.underlyingFunds.underlyingFundsTable.length + data.underlyingFunds.coinvestmentTable.length) > 26 ? 2 : 1): 0;
		let section5Pages = data.portfolioDiversification ? 1 : 0;
		let section6Pages = data.newInvestments ? 
			((data.newInvestments.newInvestmentsTable.length + data.newInvestments.newRealizationsTable.length) > 18 ? 2 : 1) : 0;
		let section7Pages = data.newFunds ? 
			((data.newFunds.length % 4 === 0) ? data.newFunds.length / 4 : Math.floor(data.newFunds.length / 4)) : 0 ;
		let section8Pages = data.portfolioFunds ? portfolioSum : 0;
		let section9Pages = data.coinvestTable ? 
			(data.coinvestTable.coinvestTableData?.length <= 14 ? 1 : 1 + Math.ceil((data.coinvestTable.coinvestTableData?.length - 14) / 20)) : 0;
		let section10Pages = data.coinvestSummary ? 
			((data.coinvestSummary.length % 2 === 0) ? data.coinvestSummary.length / 2 : Math.floor(data.coinvestSummary.length / 2) + 1) : 0;
		let section11Pages = data.portfolioBreakdown ? breakdownLength : 0;
		let section12Pages = data.companiesList ? 
			((data.companiesList.length % 4 === 0) ? data.companiesList.length / 4 : Math.floor(data.companiesList.length / 4) + 1) : 0;
		let section13Pages = 1;

		pageNumbers = {
			section1Pages, /* FundSummary */
			section2Pages, /* FundFigures */
			section3Pages, /* PortfolioInvestments */
			section4Pages, /* UnderlyingFunds */
			section5Pages, /* PortfolioDiversification */
			section6Pages, /* NewInvestments */
			section7Pages, /* NewFunds */
			section8Pages, /* PortfolioFunds */
			section9Pages, /* CoInvestments */ 
			section10Pages, /* CoInvestmentsSummary */ 
			section11Pages, /* PortfolioBreakdown */
			section12Pages, /* CompaniesList */ 
			section13Pages /* LegalTerms */
		}
		setPageNumbers(pageNumbers)
	}

	const asignDirectPageNumbers = (data: any) => {
		let coinvPages = data.coinvestAcquisition?.coinvestDetail?.length;
		let coinvestAcquisitionPages = ((coinvPages % 3 === 0) ? coinvPages / 3 : (Math.floor(coinvPages / 3) === 0 ? 1 : Math.floor(coinvPages / 3))) + 1;
		let pageNumbers: any = {}
		let section1Pages = data.fundSummary ? 1 : 0 ;
		let section2Pages = data.fundFigures ? 1 : 0;
		let section3Pages = data.fundEvolution ? 1 : 0;
		let section4Pages = data.fundCharts ? 1 : 0;
		let section5Pages = data.portfolioPerformance ? 1 : 0;
		let section6Pages = data.coinvestDetail ? data.coinvestDetail.length : 0;
		let section7Pages = data.coinvestAcquisition ? coinvestAcquisitionPages : 1;

		pageNumbers = {
			section1Pages,
			section2Pages, 
			section3Pages,
			section4Pages, 
			section5Pages, 
			section6Pages, 
			section7Pages 
		}
		setPageNumbers(pageNumbers)
	}

	const formatNumber = (num: string | number | undefined, decimals = 2): string => {
		if (num === undefined || num === null) {
			return '';
		}
	
		let parsedNumber: number;
		let isNegative = false;
	
		if (typeof num === 'string') {
			// Check if the number is negative
			if (num.startsWith('-')) {
				isNegative = true;
				num = num.slice(1); // Remove the negative sign for parsing
			}
	
			// Detect if the string is using the thousand separator format
			if (/^\d{1,3}(?:\.\d{3})*(?:,\d+)?$/.test(num)) {
				// European format: "123.456.789,12"
				num = num.replace(/\./g, '').replace(',', '.');
			} else if (/^\d{1,3}(?:,\d{3})*(?:\.\d+)?$/.test(num)) {
				// US format: "123,456,789.12"
				num = num.replace(/,/g, '');
			} else {
				// General replacement of commas to dots for simple cases like "17,97"
				num = num.replace(',', '.');
			}
			parsedNumber = Number(num);
		} else if (typeof num === 'number') {
			parsedNumber = num;
		} else {
			return String(num);
		}
	
		if (isNaN(parsedNumber)) {
			return String(num);
		}
	
		if (isNegative) {
			parsedNumber = -parsedNumber;
		}
	
		// Format the number according to the selected language
		const formattedNumber = parsedNumber.toLocaleString(selectedLanguage, {
			minimumFractionDigits: decimals,
			maximumFractionDigits: decimals
		});
	
		return formattedNumber;
		};
	

	return {
		loadingFunds,
		loadingData,
		qualitasFunds,
		selectedQualitasFund,
		sections,
		selectedSections,
		funds,
		selectedFunds,
		year,
		selectedQuarter,
		selectedLanguage,
		reportData,
		isFoF,

		handleChange,
		handleMultiselectChange,
		handleChangeSelectedSections,
		handleChangeSelectedFunds,
		sendData,
		reportName,
		pageNumbers,
		formatNumber
	};
};

export default useForm;