import { Col, Row } from "antd";
import Chart, {
  CommonSeriesSettings,
  Label,
  Series,
} from "devextreme-react/chart";

import { IUnderlyingFunds } from "./IUnderlyingFunds";
import "./UnderlyingFunds.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";

const UnderlyingFunds = ({ templateData, translations, formatNumber }: IUnderlyingFunds) => {
  const palette = ["#B3D615", "#8f8f8f", "#ababab"];

  let maxValUnderlying = 0;
  let maxValCoinvest = 0;

  for (const item of templateData.underlyingFundsTable) {
    if (item.val > maxValUnderlying) {
      maxValUnderlying = item.val;
    }
  }

  for (const item of templateData.coinvestmentTable) {
    if (item.val > maxValCoinvest) {
      maxValCoinvest = item.val;
    }
  }

  const amountLines =
    templateData.underlyingFundsTable.length +
    templateData.coinvestmentTable.length;

  let amountPages = ["Page1"];
  if (amountLines > 26) {
    amountPages.push("Page2");
  }

  let pageNum = templateData.page;

  const renderPage = (content: any, page: number) => {
	return ( <div id="underlyingFunds" contentEditable>
      <ReportHeader
        confidential={translations.confidential}
        programName={templateData.programName}
        date={`${translations[templateData.month]} ${templateData.year}`}
        section={translations.section}
        page={page}
      />
      <Row className="content" align="top" gutter={[0, 0]}>
        <Col span={24}>
          <main className="underlyingFunds">
            <p className="underlyingFunds-subtitle">{translations.section}</p>
            {content}
          </main>
        </Col>
      </Row>
    </div>
  )};

  const renderTable = () => (
    <table className="underlyingCoinvestments-table">
      <colgroup>
        <col style={{ width: "160px" }} />
        <col style={{ width: "80px" }} />
        <col style={{ width: "80px" }} />
        <col style={{ width: "80px" }} />
        <col style={{ width: "120px" }} />
        <col style={{ width: "120px" }} />
      </colgroup>
      <thead>
        <tr className="underlyingFunds-table-header">
          <th></th>
          <th colSpan={3}>{/* {translations.totalValue} */}</th>
          <th>{translations.relativeValue}</th>
          <th>{translations.absoluteValue}</th>
        </tr>
        <tr>
          <th>{translations.coinvestmentAndSecondaries}</th>
          <th>{translations.netIrr}</th>
          <th>TVPI (X)</th>
          <th>VAL.</th>
          <th>
            <p className="underlyingFunds-table-combined-cells">
              <span>DPI (X)</span>
              <span>RVPI (X)</span>
            </p>
          </th>
          <th>
            <p className="underlyingFunds-table-combined-cells">
              <span>DIST. (€ M)</span>
              <span>VAL. (€ M)</span>
            </p>
          </th>
        </tr>
      </thead>
      <tbody>
        {templateData.coinvestmentTable.map((fund: any, index: number) => {
          let dpiPercentage =
            (Number(fund.dpiX) * 100) /
            (Number(fund.dpiX) + Number(fund.rvpiX));
          let valPercentage =
            (Number(fund.dist) * 100) /
            (Number(fund.dist) + Number(fund.absoluteVal));
          if (
            index !== templateData.coinvestmentTable.length - 1 &&
            index !== templateData.coinvestmentTable.length - 2
          ) {
            return (
              <tr key={index}>
                <td>{fund.fund}</td>
                <td>
                  {formatNumber(fund.netIrr)}
                  {fund.netIrr !== "n.a." && "%"}
                </td>
                <td>{formatNumber(fund.tvpiX)}</td>
                <td>€{formatNumber(fund.val, 0)}</td>
                <td>
                  <div
                    className="progress-bar"
                    style={{
                      background: `linear-gradient(to right, #c4c4c4 ${dpiPercentage}%, #e6e6e6 ${dpiPercentage}%)`,
                    }}
                  >
                    <p>{formatNumber(fund.dpiX)}</p>
                    <p>{formatNumber(fund.rvpiX)}</p>
                  </div>
                </td>
                <td>
                  <div
                    className="progress-bar"
                    style={{
                      background: `linear-gradient(to right, #c4c4c4 ${valPercentage}%, #e6e6e6 ${valPercentage}%)`,
                    }}
                  >
                    <p>{formatNumber(fund.dist)}</p>
                    <p>{formatNumber(fund.absoluteVal)}</p>
                  </div>
                </td>
              </tr>
            );
          } else {
            return (
              <tr key={index}>
                <td>{fund.fund}</td>
                <td>
                  {formatNumber(fund.netIrr)}
                  {fund.netIrr !== "n.a." && "%"}
                </td>
                <td>{formatNumber(fund.tvpiX)}</td>
                <td>
                  €{formatNumber(fund.val, 0)}
                </td>
                <td>
                  <p className="underlyingFunds-table-combined-cells">
                    <span>{formatNumber(fund.dpiX)}</span>
                    <span>{formatNumber(fund.rvpiX)}</span>
                  </p>
                </td>
                <td>
                  <p className="underlyingFunds-table-combined-cells">
                    <span>{formatNumber(fund.dist)}</span>
                    <span>{formatNumber(fund.absoluteVal)}</span>
                  </p>
                </td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );

  return (
    <>
      {amountPages.map((_page, index) => {
        if (index === 0) {
          return renderPage(
            <>
              <div className="underlyingFunds-header">
                <div className="underlyingFunds-headerInfo">
                  <div className="underlyingFunds-headerInfo-item">
                    <p>TVPI</p>
                    <p>{formatNumber(templateData.tvpiVal)}x</p>
                  </div>
                  <div className="underlyingFunds-headerInfo-item">
                    <p>{translations.distributions}</p>
                    <p>€{formatNumber(templateData.distributions, 1)}M</p>
                  </div>
                  <div className="underlyingFunds-headerInfo-item">
                    <p>{translations.residual}</p>
                    <p>€{formatNumber(templateData.residual, 1)}M</p>
                  </div>
                </div>
                <div className="underlyingFunds-chart">
                  <p>TVPI: {translations.currentVsPrev}</p>
                  <div className="underlyingFunds-chart-section">
                    <div>
                      <div className="underlyingFunds-chart-labels">
                        <p>{formatNumber(templateData.tvpi.tvpiTotalCurrent, 2)}x</p>
                        <p>{formatNumber(templateData.tvpi.tvpiTotalPrevious, 2)}x</p>
                      </div>
                      <Chart
                        id="chart"
                        dataSource={templateData.tvpiChart}
                        height={140}
                        width={210}
                        palette={palette}
                        legend={{ visible: false }}
                        animation={false}
                        argumentAxis={{
                          visible: true,
						  color: '#e6e6e6',
                          label: {
                            overlappingBehavior: "hide",
                            font: { size: 11 },
                          },
                          grid: {
                            visible: false,
                          },
                          tick: { visible: false },
                        }}
                        valueAxis={{
                          visible: false,
                          maxValueMargin: 0.05,
                          grid: {
                            visible: false,
                          },
                          showZero: true,
                          tick: { visible: false },
                          label: {
                            visible: false,
                          },
                        }}
                      >
                        <CommonSeriesSettings
                          argumentField="quarter"
                          type="stackedbar"
                          barWidth={50}
                        >
                          <Label
                            visible={true}
                            backgroundColor="none"
                            font={{ color: "black", size: "8px" }}
                            customizeText={(e) => {
								if(e.value === 0) {
									return "";
								}
                              return formatNumber(e.valueText, 2) + "x";
                            }}
                          />
                        </CommonSeriesSettings>
                        <Series valueField="rpi" name="RPI" />
                        <Series valueField="dpi" name="DPI" />
                      </Chart>
                    </div>
                    <div className="underlyingFunds-chart-legend">
                      <div className="underlyingFunds-chart-legend-item">
                        <div />
                        <p>{translations.rpi}</p>
                      </div>
                      <div className="underlyingFunds-chart-legend-item">
                        <div />
                        <p>DPI</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="underlyingFunds-content">
                <table className="underlyingFunds-table">
                  <colgroup>
                    <col style={{ width: "160px" }} />
                    <col style={{ width: "80px" }} />
                    <col style={{ width: "80px" }} />
                    <col style={{ width: "80px" }} />
                    <col style={{ width: "120px" }} />
                    <col style={{ width: "120px" }} />
                  </colgroup>
                  <thead>
                    <tr className="underlyingFunds-table-header">
                      <th></th>
                      <th colSpan={3}>{/* {translations.totalValue} */}</th>
                      <th>{translations.relativeValue}</th>
                      <th>{translations.absoluteValue}</th>
                    </tr>
                    <tr>
                      <th>{translations.underlying}</th>
                      <th>{translations.netIrr}</th>
                      <th>TVPI (X)</th>
                      <th>VAL.</th>
                      <th>
                        <p className="underlyingFunds-table-combined-cells">
                          <span>DPI (X)</span>
                          <span>RVPI (X)</span>
                        </p>
                      </th>
                      <th>
                        <p className="underlyingFunds-table-combined-cells">
                          <span>DIST. (€ M)</span>
                          <span>VAL. (€ M)</span>
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {templateData.underlyingFundsTable.map(
                      (fund: any, index: number) => {
                        let dpiPercentage =
                          (Number(fund.dpiX) * 100) /
                          (Number(fund.dpiX) + Number(fund.rvpiX));
                        let valPercentage =
                          (Number(fund.dist) * 100) /
                          (Number(fund.dist) + Number(fund.absoluteVal));
                        if (
                          index !==
                          templateData.underlyingFundsTable.length - 1
                        ) {
                          return (
                            <tr key={index}>
                              <td>{fund.fund}</td>
                              <td>
                                {formatNumber(fund.netIrr)}
                                {fund.netIrr !== "n.a." && "%"}
                              </td>
                              <td>{formatNumber(fund.tvpiX)}</td>
                              <td>
                                €
                                {formatNumber(fund.val, 0)}
                              </td>
                              <td>
                                <div
                                  className="progress-bar"
                                  style={{
                                    background: `linear-gradient(to right, #c4c4c4 ${dpiPercentage}%, #e6e6e6 ${dpiPercentage}%)`,
                                  }}
                                >
                                  <p>{formatNumber(fund.dpiX)}</p>
                                  <p>{formatNumber(fund.rvpiX)}</p>
                                </div>
                              </td>
                              <td>
                                <div
                                  className="progress-bar"
                                  style={{
                                    background: `linear-gradient(to right, #c4c4c4 ${valPercentage}%, #e6e6e6 ${valPercentage}%)`,
                                  }}
                                >
                                  <p>{formatNumber(fund.dist)}</p>
                                  <p>{formatNumber(fund.absoluteVal)}</p>
                                </div>
                              </td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr key={index}>
                              <td>{fund.fund}</td>
                              <td>
                                {formatNumber(fund.netIrr)}
                                {fund.netIrr !== "n.a." && "%"}
                              </td>
                              <td>{formatNumber(fund.tvpiX)}</td>
                              <td>
                                €{formatNumber(fund.val, 0)}
                              </td>
                              <td>
                                <p className="underlyingFunds-table-combined-cells">
                                  <span>{formatNumber(fund.dpiX)}</span>
                                  <span>{formatNumber(fund.rvpiX)}</span>
                                </p>
                              </td>
                              <td>
                                <p className="underlyingFunds-table-combined-cells">
                                  <span>{formatNumber(fund.dist)}</span>
                                  <span>{formatNumber(fund.absoluteVal)}</span>
                                </p>
                              </td>
                            </tr>
                          );
                        }
                      }
                    )}
                  </tbody>
                </table>
                {amountLines < 26 && renderTable()}
              </section>
            </>
          , (pageNum));
        }
        if (index === 1) {
          return renderPage(renderTable(), (pageNum + 1));
        }
      })}
    </>
  );
};

export default UnderlyingFunds;
