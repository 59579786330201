import { useEffect, useRef, useState } from "react";
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';

import { IUsePreview } from "./IUsePreview";

const usePreview = ({ selectedSections, reportName }: IUsePreview) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [previewSectionSelected, setPreviewSectionSelected] = useState<any>();
	const [previewSections, setPreviewSections] = useState<any[]>([]);
	const previewRef = useRef<any>(null);

	useEffect(() => {
		const sections = selectedSections.filter((section: any) => section.value !== '0' ? section : false);
		setPreviewSections(sections);
	}, [selectedSections]);

	const scrollToSection = (sectionId: string) => {
		const sectionElement = document.getElementById(sectionId);
		if (sectionElement && previewRef.current) {
			const containerRect = previewRef.current.getBoundingClientRect();
			const sectionRect = sectionElement.getBoundingClientRect();
			const scrollTop = sectionRect.top - containerRect.top + previewRef.current.scrollTop;
			previewRef.current.scrollTo({
				top: scrollTop,
				behavior: 'smooth',
			});
		}
	};

	const handlePreviewSectionSelected = (value: string, section: any) => {
		setPreviewSectionSelected(section);
		scrollToSection(section.sectionId);
	};
	
	const downloadReport = async (template: any) => {
		printDiv('wrapper');
	};

	const printDiv = (elemento:any) => {
/* 		var divElements = document.getElementById(elemento)?.innerHTML;
		//Get the HTML of whole page
		var oldPage = document.body.innerHTML;
		//Reset the page's HTML with div's HTML only
		document.body.innerHTML =
		  "<html><head><title></title></head><body>" +
		  divElements +
		  "</body>";
		//Print Page
			window.print();
			document.body.innerHTML = oldPage;
		
		//Restore orignal HTML
 		*/
		const iframe = document.getElementById(
			"ifmcontentstoprint"
		  ) as HTMLIFrameElement;
		  const pri = iframe?.contentWindow;
	  
		  if (pri) {
			pri.document.open();
			const styles = Array.from(
			  document.querySelectorAll('link[rel="stylesheet"], style')
			)
			  .map((style) => style.outerHTML)
			  .join("");
			pri.document.write(`
					  <html>
						  <head>
							  ${styles}
						  </head>
						  <body>
							  ${previewRef.current.innerHTML}
						  </body>
					  </html>
				  `);
	  
			pri.document.close();
			pri.focus();
			pri.addEventListener("load", () => {
			  pri.print();
			});
		  }
	  
	  }
	  

	return {
		loading,
		previewSectionSelected,
		previewSections,
		previewRef,

		downloadReport,
		handlePreviewSectionSelected,
	}
};

export default usePreview;