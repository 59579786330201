import { useEffect, useState } from "react";
import dayjs from "dayjs";

import { ICoinvestAcquisition } from "./ICoinvestAcquisition";

import "./CoinvestAcquisition.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";

const CoinvestAcquisition = ({templateData, translations, formatNumber}: ICoinvestAcquisition) => {
  const [groups, setGroups] = useState<any[]>([]);
  useEffect(() => {
	let groupsCopy = [...groups];
	if (templateData.coinvestDetail) {
	  templateData.coinvestDetail.forEach((item: any, index: any) => {
		const groupIndex = Math.floor(index / 3); // Divide por 3 y toma la parte entera.
		if (!groupsCopy[groupIndex]) {
		  groupsCopy[groupIndex] = [];
		}
		groupsCopy[groupIndex].push(item);
	  });
	}    
	setGroups(groupsCopy);
  }, [templateData.coinvestDetail]);

  let page = templateData.page - 1;

  return (
    <>
      {groups.map((group: any) => {
        page ++;
        return (
        <div id="coinvestAcquisition" contentEditable className="pagebreak">
          <ReportHeader
            confidential={translations.confidential}
            programName={templateData.programName}
            date={`${translations[templateData.month]} ${templateData.year}`}
            section={translations.section}
            page={page}
          />
          <main className="coinvestAcquisition">
            <section className="coinvestAcquisition-content">
              <div id="coinvestList">
                {group.map((item: any, index: number) => (
                  <div className="coinvestAcquisition-item">
                    <div className="coinvestAcquisition-logo-title">
                      <p>{item.coinvestName}</p>
                      <div className="coinvestAcquisition-logo-title-description">
                        <p>
                          {item.sector}
                          <span>
                            {translations.coinvestWith.replace(
                              "[coinvestWith]",
                              item.coinvest
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="coinvestAcquisition-headerInfo">
                      <div className="coinvestAcquisition-headerInfo-item">
                        <p>{translations.entryInvestment}</p>
                        <p>€{formatNumber(item.initialInvestment, 1)}M</p>
                      </div>
                      <div className="coinvestAcquisition-headerInfo-item">
                        <p>{translations.entryDate}</p>
                        <p>{item.investDate}</p>
                      </div>
                      <div className="coinvestAcquisition-headerInfo-item">
                        <p>{translations.country}</p>
                        <p>{item.country}</p>
                      </div>
                      <div className="coinvestAcquisition-headerInfo-item item-grey">
                        <p>{translations.gp}</p>
                        <p>{item.gestor}</p>
                      </div>
                      <div className="coinvestAcquisition-headerInfo-item">
                        <img
                          className="coinvestAcquisition-logo"
                          src={item.img}
                          alt="logo"
                        />
                      </div>
                    </div>
                    <div className="coinvestAcquisition-description">
                      <p className="coinvestAcquisition-description-title">
						{translations.description}</p>
                      <div className="coinvestAcquisition-description-text">
                        {item.descriptionList.map((descriptionItem: any) => (
                          <p>{descriptionItem}</p>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </main>
          <div style={{ pageBreakAfter: "always" }}></div>
        </div>
      )})}
    </>
  );
};

export default CoinvestAcquisition;
