import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP2Es = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENCIAL"
      date={date}
      section="Glosario de términos"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">DACH</p>
          <p className="glossary-term-item">Alemania, Austria y Suiza.</p>
          <p className="glossary-term-item-bold">Desembolsado</p>
          <p className="glossary-term-item">
            Dinero que los inversores han aportado realmente al fondo y ha sido
            utilizado para realizar inversiones.
          </p>
          <p className="glossary-term-item-bold">Distribuciones</p>
          <p className="glossary-term-item">
            Ganancias que recibe el inversor durante el periodo de desinversión
            del fondo. Las distribuciones son el resultado de la suma del
            capital aportado en el fondo más las plusvalías.
          </p>
          <p className="glossary-term-item-bold">DKK</p>
          <p className="glossary-term-item">Corona danesa.</p>
          <p className="glossary-term-item-bold">DPI</p>
          <div className="glossary-term-item-special">
            <p className="glossary-term-item-special-text">
              “Distributed to Paid-In Capital”. Distribuciones recibidas sobre
              el importe desembolsado calculado de la siguiente forma:
            </p>
            <div className="glossary-term-item-form">
              <p>DPI =</p>
              <div>
                <p className="glossary-term-item-lined">
                  Distribuciones Recibidas
                </p>
                <p>Total Desembolsos</p>
              </div>
            </div>
          </div>
          <p className="glossary-term-item-bold">EBITDA</p>
          <p className="glossary-term-item">
            "Earnings Before lnterest, Taxes, Depreciation and Amortization".
            Resultado de una empresa antes de intereses, impuestos, depreciación
            y amortización.
          </p>
          <p className="glossary-term-item-bold">EUR</p>
          <p className="glossary-term-item">Euro. </p>
          <p className="glossary-term-item-bold">EV</p>
          <p className="glossary-term-item">
            "Enterprise Value". Valor total de una empresa que incluye el valor
            del capital y la deuda.
          </p>
          <p className="glossary-term-item-bold">GBP</p>
          <p className="glossary-term-item">Libra esterlina.</p>
          <p className="glossary-term-item-bold">GP</p>
          <p className="glossary-term-item">
            “General Partner”. Gestor de un fondo de inversión de capital
            privado.
          </p>
          <p className="glossary-term-item-bold">Growth</p>
          <p className="glossary-term-item">
            Estrategia de inversión en la que un fondo aporta capital a empresas
            en crecimiento a cambio de una participación en la empresa, para
            financiar los planes de crecimiento de la misma.
          </p>
          <p className="glossary-term-item-bold">Llamada de capital</p>
          <p className="glossary-term-item">
            Cuando el GP pide a los inversores que desembolsen capital para
            realizar inversiones desde el fondo.
          </p>
          <p className="glossary-term-item-bold">LTM</p>
          <p className="glossary-term-item">
            "Last Twelve Months". Últimos Doce Meses.
          </p>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP2Es;
