import Navbar, { NavbarApi, useNavbar } from "@qualitas/navbar";

const MenuNavbar = (props: any) => {
  const {
    baseUrl,
    getAuthToken,
    logout,
    authProps,
    sendResetPasswordEmail,
    navigate,
  } = props;

  let migratedUrl = process.env.REACT_APP_migratedUrl;
  let notMigratedUrl = process.env.REACT_APP_trackWizardUrl;

  const idSystemMaster = 2;
  const api = NavbarApi(baseUrl, getAuthToken);
  const { translations, menuItems, ...NavbarProps} = useNavbar({
    api,
    idSystemMaster,
    userData: { ...authProps},
    migratedUrl,
    notMigratedUrl,
    navigate,
    sendResetPasswordEmail: () => sendResetPasswordEmail(authProps.userEmail),
    language: 'es'
  });

  /* Se reemplazan los items del menú por el link a Track Wizard */
  let menuTrackWizard = [{
    "menu": "Track Wizard",
    "items": [
        {
            "key": "8-Track Wizard",
            "label": "Track Wizard",
            "url": '/',
            "migrated": false,
            "children": []
        }
    ],
    "type": "link",
    "url": notMigratedUrl,
    onClick: () => window.location.href = `${notMigratedUrl}/PortfolioDetail`
}]
  
  return (
      <Navbar
        {...NavbarProps}
        userName={NavbarProps.menuName ?? ""}
        logout={logout}
        menuItems={menuTrackWizard}
        reportIncident={() => console.log("report")}
        isBackoffice={false}
        translations={translations}
      />
  );
};

export default MenuNavbar;
