import { Routes, Route, useNavigate } from "react-router-dom";

import Login, { PrivateRoute, AuthAPI, useAuth } from '@qualitas/auth';

import PrivateContentLayout from "./PrivateContentLayout";

import './App.scss';
import '../node_modules/@qualitas/auth/dist/Auth.scss';

const App = () => {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_NEW_WEB_API;

	const authAPI = AuthAPI(baseUrl);
	const authProps = useAuth({
		api: authAPI,
		navigate,
		redirectUrl: '/',
		idSystemMaster: 8,
		systemMasterName: 'Direct Reporting',
		redirectPassResetUrl: process.env['REACT_APP_Pass_Reset']
	});

	return (
		<Routes>
			<Route path="/login" element={<Login {...authProps} appName="Reporting"/>}/>
			<Route element={<PrivateRoute validateAuthToken={authProps.validateAuthToken}/>}>
				<Route path="*" element={
					<PrivateContentLayout
						baseUrl={baseUrl}
						getAuthToken={authProps.getAuthToken}
						userLogo={authProps.userLogo}
						logOut={authProps.logOut}
						validateAuthToken={authProps.validateAuthToken}
						authProps={authProps}
					/>
				}/>
			</Route>
		</Routes>
	);
}

export default App;
