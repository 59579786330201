import { ICoinvestCover } from "./ICoinvestCover";

import "./CoinvestCover.scss";

const CoinvestCover = ({ templateData, translations }: ICoinvestCover) => (
  <div id="coinvestCover" contentEditable className="pagebreak">
    <main className="coinvestCover">
      <div>
        <h2 className="coinvestCover-title">
          {templateData.programName}
        </h2>
        <p className="coinvestCover-subtitle">
          {translations.subtitle.replace("[quarter]", `${templateData.quarter} ${templateData.year}`)}
        </p>
        <p className="coinvestCover-subtitle">
          {translations.text}
        </p>
      </div>
    </main>
  </div>
);

export default CoinvestCover;
