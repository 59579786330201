import { Col, Row } from "antd";
import { useState } from "react";
import Chart, { CommonSeriesSettings, Series } from "devextreme-react/chart";

import { ICoinvestTable } from "./ICoinvestTable";
import "./CoinvestTable.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";

const CoinvestTable = ({
  templateData,
  translations,
  formatNumber,
}: ICoinvestTable) => {
  const palette = ["#B3D615", "#8f8f8f", "#ababab"];

  let page = templateData.page - 1;

  let maxWidth = 0;
  for (const item of templateData.coinvestTableData) {
    if (item.residual + item.distribution > maxWidth) {
      maxWidth = item.residual + item.distribution;
    }
  }

  let itemsPerPage = 13;
  const groupedData = [];
  const data = templateData.coinvestTableData.sort(
    (a: any, b: any) =>
      b.residual + b.distribution - (a.residual + a.distribution)
  );

  for (let i = 0; i < data.length; ) {
    if (i === 0) {
      groupedData.push(data.slice(i, i + itemsPerPage));
      i += itemsPerPage;
    } else {
      itemsPerPage = 20;
      groupedData.push(data.slice(i, i + itemsPerPage));
      i += itemsPerPage;
    }
  }

  return (
    <>
      {groupedData.map((group: any, index: number) => {
        page++;
        return index === 0 ? (
          <div id="coinvestTable" contentEditable key={index}>
            <ReportHeader
              confidential={translations.confidential}
              programName={templateData.programName}
              date={`${translations[templateData.month]} ${templateData.year}`}
              section={translations.section}
              page={page}
            />
            <Row className="content" align="top" gutter={[0, 0]}>
              <Col span={24}>
                <main className="coinvestTable">
                  <p className="coinvestTable-subtitle">
                    {translations.section}
                  </p>
                  <div className="coinvestTable-header">
                    <div className="coinvestTable-headerInfo">
                      <div className="coinvestTable-headerInfo-item">
                        <p>{translations.nInvestments}</p>
                        <p>{templateData.nInvestments}</p>
                      </div>
                      <div className="coinvestTable-headerInfo-item">
                        <p>{translations.totalInvested}</p>
                        <p>€{formatNumber(templateData.totalInvested, 1)}M</p>
                      </div>
                      <div className="coinvestTable-headerInfo-item">
                        <p>% {translations.totalInvested}</p>
                        <p>
                          {formatNumber(templateData.percentageInvested, 2)}%
                        </p>
                      </div>
                    </div>
                    <div className="coinvestTable-chart">
                      <p>{translations.evolution}</p>
                      <div className="coinvestTable-chart-section">
                        <div>
                          <div className="coinvestTable-chart-labels">
                            <p>
                              {formatNumber(
                                templateData.tvpi.tvpiTotalCurrent,
                                2
                              )}
                              x
                            </p>
                            <p>
                              {formatNumber(
                                templateData.tvpi.tvpiTotalPrevious,
                                2
                              )}
                              x
                            </p>
                          </div>
                          <Chart
                            id="chart"
                            dataSource={templateData.tvpiChart}
                            height={130}
                            width={210}
                            palette={palette}
                            legend={{ visible: false }}
                            animation={false}
                            argumentAxis={{
                              visible: true,
                              color: "#e6e6e6",

                              label: {
                                overlappingBehavior: "hide",
                                font: { size: 11 },
                              },
                              grid: {
                                visible: false,
                              },
                              tick: { visible: false },
                            }}
                            valueAxis={{
                              visible: false,
                              maxValueMargin: 0.05,
                              grid: {
                                visible: false,
                              },
                              showZero: true,
                              tick: { visible: false },
                              label: {
                                visible: false,
                              },
                            }}
                          >
                            <CommonSeriesSettings
                              argumentField="quarter"
                              type="stackedbar"
                              barWidth={50}
                            />
                            <Series
                              valueField="rpi"
                              name="RPI"
                              label={{
                                visible: true,
                                font: {
                                  size: 8.5,
                                  color: "black",
                                },
                                position: "inside",
                                backgroundColor: "none",
                                customizeText: (e: any) => {
                                  if (e.valueText > 0) {
                                    return `${formatNumber(e.valueText, 2)}x`;
                                  }
                                },
                              }}
                            />
                            <Series
                              valueField="dpi"
                              name="DPI"
                              label={{
                                visible: true,
                                font: {
                                  size: 8.5,
                                  color: "black",
                                },
                                position: "inside",
                                backgroundColor: "none",
                                customizeText: (e: any) => {
                                  if (e.valueText > 0) {
                                    return `${formatNumber(e.valueText, 2)}x`;
                                  }
                                },
                              }}
                            />
                          </Chart>
                        </div>
                        <div className="coinvestTable-chart-legend">
                          <div className="coinvestTable-chart-legend-item">
                            <div />
							<p>{translations.rpi}</p>
                            <span>
                              {/* {formatNumber(templateData.tvpi.rpiCurrentVal, 2)}
                              x */}
                            </span>
                          </div>
                          <div className="coinvestTable-chart-legend-item">
                            <div />
                            <p>DPI</p>
                            <span>
                              {/* {formatNumber(templateData.tvpi.dpiCurrentVal, 2)}
                              x */}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section className="coinvestTable-content">
                    <div className="coinvestTable-analysis">
                      <p>{translations.evolucionOfLastQuarter}</p>
                      <table className="coinvestTable-analysis-table">
                        <colgroup>
                          <col style={{ width: "40px" }} />
                          <col style={{ width: "100px" }} />
                          <col style={{ width: "90px" }} />
                          <col style={{ width: "60px" }} />
                          <col style={{ width: "60px" }} />
                          <col style={{ width: "60px" }} />
                          <col style={{ width: "60px" }} />
                          <col style={{ width: "60px" }} />
                          <col style={{ width: "110px" }} />
                          <col style={{ width: "50px" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>{translations.state}*</th>
                            <th>{translations.name}</th>
                            <th>{translations.country}</th>
                            <th>{translations.ev} </th>
                            <th>{translations.dateInv}</th>
                            <th className="coinvestTable-analysis-grey">
                              {translations.holdingPeriod}
                            </th>
                            <th className="coinvestTable-analysis-grey">
                              {translations.investment}
                            </th>
                            <th className="coinvestTable-analysis-grey">
                              % {translations.fund}
                            </th>
                            <th className="coinvestTable-analysis-grey">
                              MOIC **
                            </th>
                            <th className="coinvestTable-analysis-grey">
                              {translations.opinion}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {group.map((row: any) => {
                            let width =
                              ((row.distribution + row.residual) * 100) /
                              maxWidth;
                            let distPercentage =
                              (((row.distribution * 100) /
                                (row.distribution + row.residual)) *
                                width) /
                              100;

                            return (
                              <tr>
                                <td>{row.state}</td>
                                <td>{row.name}</td>
                                <td className="coinvestTable-analysis-small">
                                  {row.country} <br /> {row.activity}
                                </td>
                                <td>{row.ev ? row.ev : "-"}</td>
                                <td>{row.date}</td>
                                <td className="coinvestTable-analysis-grey">
                                  {formatNumber(row.holdingPeriod, 1)}{" "}
                                  {translations.years}
                                </td>
                                <td className="coinvestTable-analysis-grey">
                                  €{formatNumber(row.investment, 2)}m
                                </td>
                                <td className="coinvestTable-analysis-grey">
                                  {formatNumber(row.qfPercentage, 2)}%
                                </td>
                                <td className="coinvestTable-analysis-grey">
                                  <div
                                    className="progress-bar"
                                    style={{
                                      background: `linear-gradient(to right, #c4c4c4 ${distPercentage}%, #fff ${distPercentage}%, #fff ${width}%,#f0f0f0 ${width}%)`,
                                    }}
                                  >
                                    <span>{formatNumber(row.moc, 2)}x</span>
                                  </div>
                                </td>
                                <td className="coinvestTable-analysis-grey">
                                  <div
                                    className={`manager-opinion opt-${row.opinion}`}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="coinvestTable-analysis-references">
                        <div>
                          <span>{translations.ref1}</span>
                          <br />
                          <div className="portfolioFunds-analysis-references-moc">
                            <div className="moc-reference ref-1" />
                            {translations.ref2}
                            <div className="moc-reference ref-2" />
                            {translations.ref3}
                          </div>
                        </div>
                        <div className="coinvestTable-analysis-ref-opinions">
                          <div className="coinvestTable-analysis-ref-opinion-item">
                            <span>{translations.option1}</span>
                            <div className="manager-opinion opt-1" />
                          </div>
                          <div className="coinvestTable-analysis-ref-opinion-item">
                            <span>{translations.option2}</span>
                            <div className="manager-opinion opt-2" />
                          </div>
                          <div className="coinvestTable-analysis-ref-opinion-item">
                            <span>{translations.option3}</span>
                            <div className="manager-opinion opt-3" />
                          </div>
                          <div className="coinvestTable-analysis-ref-opinion-item">
                            <span>{translations.option4}</span>
                            <div className="manager-opinion opt-4" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </main>
              </Col>
            </Row>
          </div>
        ) : (
          <div id="coinvestTable" contentEditable key={index}>
            <ReportHeader
              confidential={translations.confidential}
              programName={templateData.programName}
              date={`${translations[templateData.month]} ${templateData.year}`}
              section={translations.section}
              page={page}
            />
            <Row className="content" align="top" gutter={[0, 0]}>
              <Col span={24}>
                <main className="coinvestTable">
                  <section className="coinvestTable-content">
                    <div className="coinvestTable-analysis">
                      <table className="coinvestTable-analysis-table">
                        <colgroup>
                          <col style={{ width: "40px" }} />
                          <col style={{ width: "100px" }} />
                          <col style={{ width: "90px" }} />
                          <col style={{ width: "60px" }} />
                          <col style={{ width: "60px" }} />
                          <col style={{ width: "60px" }} />
                          <col style={{ width: "60px" }} />
                          <col style={{ width: "60px" }} />
                          <col style={{ width: "110px" }} />
                          <col style={{ width: "50px" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>{translations.state}*</th>
                            <th>{translations.name}</th>
                            <th>{translations.country}</th>
                            <th>{translations.ev} </th>
                            <th>{translations.dateInv}</th>
                            <th className="coinvestTable-analysis-grey">
                              {translations.holdingPeriod}
                            </th>
                            <th className="coinvestTable-analysis-grey">
                              {translations.investment}
                            </th>
                            <th className="coinvestTable-analysis-grey">
                              % {translations.fund}
                            </th>
                            <th className="coinvestTable-analysis-grey">
                              MOIC **
                            </th>
                            <th className="coinvestTable-analysis-grey">
                              {translations.opinion}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {group.map((row: any) => {
                            let width =
                              ((row.distribution + row.residual) * 100) /
                              maxWidth;
                            let distPercentage =
                              (((row.distribution * 100) /
                                (row.distribution + row.residual)) *
                                width) /
                              100;
                            return (
                              <tr>
                                <td>{row.state}</td>
                                <td>{row.name}</td>
                                <td className="coinvestTable-analysis-small">
                                  {row.country} <br /> {row.activity}
                                </td>
                                <td>{row.ev ? row.ev : "-"}</td>
                                <td>{row.date}</td>
                                <td className="coinvestTable-analysis-grey">
                                  {formatNumber(row.holdingPeriod, 1)}{" "}
                                  {translations.years}
                                </td>
                                <td className="coinvestTable-analysis-grey">
                                  €{formatNumber(row.investment, 2)}m
                                </td>
                                <td className="coinvestTable-analysis-grey">
                                  {formatNumber(row.qfPercentage, 2)}%
                                </td>
                                <td className="coinvestTable-analysis-grey">
                                  <div
                                    className="progress-bar"
                                    style={{
                                      background: `linear-gradient(to right, #c4c4c4 ${distPercentage}%, #fff ${distPercentage}%, #fff ${width}%,#f0f0f0 ${width}%)`,
                                    }}
                                  >
                                    <span>{formatNumber(row.moc, 2)}x</span>
                                  </div>
                                </td>
                                <td className="coinvestTable-analysis-grey">
                                  <div
                                    className={`manager-opinion opt-${row.opinion}`}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="coinvestTable-analysis-references">
                        <div>
                          <span>{translations.ref1}</span>
                          <br />
                          <div className="portfolioFunds-analysis-references-moc">
                            <div className="moc-reference ref-1" />
                            {translations.ref2}
                            <div className="moc-reference ref-2" />
                            {translations.ref3}
                          </div>
                        </div>
                        <div className="coinvestTable-analysis-ref-opinions">
                          <div className="coinvestTable-analysis-ref-opinion-item">
                            <span>{translations.option1}</span>
                            <div className="manager-opinion opt-1" />
                          </div>
                          <div className="coinvestTable-analysis-ref-opinion-item">
                            <span>{translations.option2}</span>
                            <div className="manager-opinion opt-2" />
                          </div>
                          <div className="coinvestTable-analysis-ref-opinion-item">
                            <span>{translations.option3}</span>
                            <div className="manager-opinion opt-3" />
                          </div>
                          <div className="coinvestTable-analysis-ref-opinion-item">
                            <span>{translations.option4}</span>
                            <div className="manager-opinion opt-4" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </main>
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default CoinvestTable;
