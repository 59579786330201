import { Col, Row } from "antd";

import { IPortfolioPerformance } from "./IPortfolioPerformance";

import "./PortfolioPerformance.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";

const PortfolioPerformance = ({ templateData, translations, formatNumber }: IPortfolioPerformance) => {	
	return (
		<div id="portfolioPerformance" contentEditable className="pagebreak">
			<ReportHeader
				programName={templateData.programName}
				date={`${translations[templateData.month]} ${templateData.year}`}
				section={translations.section}
				confidential={translations.confidential}
				page={templateData.page}
			/>
			<Row className="content" gutter={[0, 0]}>
				<Col className="content">
					<main className="portfolioPerformance">
						<section className="portfolioPerformance-content">
							<p className="portfolioPerformance-subtitle">{translations.portfolioPerformance}</p>
							<table
								className="portfolioPerformance-table"
								id="portfolioPerformance-table"
							>
								<colgroup>
									<col style={{width: "300px"}} />
									<col style={{width: "200px"}} />
									<col style={{width: "100px"}} />
									<col style={{width: "100px"}} />
									<col style={{width: "80px"}} />
									<col style={{width: "60px"}} />
									<col style={{width: "80px"}} />
									<col style={{width: "80px"}} />
									<col style={{width: "80px"}} />
									<col style={{width: "80px"}} />
								</colgroup>
								<thead>
									<tr>
										<th colSpan={3}></th>
										<th className="portfolioPerformance-table-title-line" colSpan={4}>{translations.inM}</th>
									</tr>
									<tr>
										<th className="portfolioPerformance-table-title">{translations.investment}</th>
										<th className="portfolioPerformance-table-title">{translations.gp}</th>
										<th className="portfolioPerformance-table-title">{translations.entryDate}</th>
										<th className="portfolioPerformance-table-title">{translations.initialInvestment} </th>
										<th className="portfolioPerformance-table-title">{translations.totalInvestment} </th>
										<th className="portfolioPerformance-table-title">{translations.realisedValue} </th>
										<th className="portfolioPerformance-table-title">{translations.unrealisedValue} </th>
										<th className="portfolioPerformance-table-title">{translations.grossMoc}</th>
										<th className="portfolioPerformance-table-title">{translations.currency}</th>
										<th className="portfolioPerformance-table-title">{translations.fxMoc}</th>
									</tr>
								</thead>
								<tbody>
									{
										templateData.portfolioRows
											? templateData.portfolioRows.map((objeto: any) => (
												<tr className="portfolioPerformance-table-boldLine">
													{Object.keys(objeto).map((key: any) => (
														<td>{formatNumber(objeto[key], 2)}</td>
													))}
												</tr>
											))
											: null
									}
									{
										templateData.totalsPortfolioRows
										? templateData.totalsPortfolioRows.map((item: any) => {
											const { title, ...datos } = item;
											return (
												<>
												<tr className="portfolioPerformance-table-totals">
													<td colSpan={3}>{title}</td>
													{
														Object.values(datos).map((item: any) => (
															<td>{item === "-" ? "-" : formatNumber(item, 2)}</td>
															))
														}
														<td></td>
														<td></td>
												</tr>
												</>
											);
										})
										: null
									}
								</tbody>
							</table>
							<div className="portfolioPerformance-references">
								<p>
									<strong>{translations.note}</strong> {translations.noteContent}
								</p>
							</div>
						</section>					
					</main>
				</Col>
			</Row>
		</div>
	);
};

export default PortfolioPerformance;