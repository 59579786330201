import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP1En = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENTIAL"
      date={date}
      section="Glossary"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <p className="glossary-content-title">Terms Glossary</p>
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">202X A</p>
          <p className="glossary-term-item">
            Actual financial data for that year.
          </p>
          <p className="glossary-term-item-bold">202X B</p>
          <p className="glossary-term-item">
            Budgeted financial data for that year.
          </p>
          <p className="glossary-term-item-bold">Add-on</p>
          <p className="glossary-term-item">
            Acquisitions of companies made to integrate with another company,
            increasing its activity and size.
          </p>
          <p className="glossary-term-item-bold">Benelux</p>
          <p className="glossary-term-item">
            Belgium, the Netherlands, and Luxembourg.
          </p>
          <p className="glossary-term-item-bold">Buy & build</p>
          <p className="glossary-term-item">
            Investment strategy that involves the initial acquisition of an
            established, well-positioned company, followed by the acquisition of
            smaller companies to create a larger, high growth group.{" "}
          </p>
          <p className="glossary-term-item-bold">Buyout</p>
          <p className="glossary-term-item">
            Investment strategy that focuses on acquiring mature companies that
            generate profits, with sustainable debt levels and an established
            business. The fund often uses debt to finance the purchase, seeks
            inefficiencies to make operational and financial improvements, and
            then sells the company.
          </p>
          <p className="glossary-term-item-bold">Capex</p>
          <p className="glossary-term-item">
            Funds used by a company to acquire, upgrade, and maintain physical
            assets such as property, plants, buildings, technology, or
            equipment.
          </p>
          <p className="glossary-term-item-bold">Capital Call</p>
          <p className="glossary-term-item">
            Drawdown request from the GP to investors to make new investments
            from the fund.
          </p>
          <p className="glossary-term-item-bold">CEO</p>
          <p className="glossary-term-item">Chief Executive Officer.</p>
          <p className="glossary-term-item-bold">CFO</p>
          <p className="glossary-term-item">Chief Financial Officer.</p>
          <p className="glossary-term-item-bold">CHF</p>
          <p className="glossary-term-item">Swiss franc.</p>
          <p className="glossary-term-item-bold">Co-investment</p>
          <p className="glossary-term-item">
            Direct investment in a specific asset or company led by a GP who
            acquires a controlling position. GPs often invite other funds to
            co-invest when the size of the transaction is too large for their
            fund.
          </p>
          <p className="glossary-term-item-bold">Committed capital</p>
          <p className="glossary-term-item">
            Amount of money that an investor has committed to contribute to an
            investment fund.
          </p>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP1En;
