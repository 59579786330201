import { AxiosResponse } from "axios";
import API from "./api";
import { notification } from "antd";

const CommonApi = ({ baseUrl, getAuthToken }: any) => {
  const api = API(baseUrl!, getAuthToken);

  const getPeips = () => {
    const serviceUrl = "/masters-api/Peips";
    return api
      .get(serviceUrl)
      .then((response: AxiosResponse) => response.data)
      .catch((error) =>
        notification.error({ message: `Error with ${baseUrl}${serviceUrl}` })
      );
  };

  return {
    getPeips,
  };
};

export default CommonApi;
