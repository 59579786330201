import DataGrid, { Editing, Column, RequiredRule, Lookup } from 'devextreme-react/data-grid';

import NetCashAPI from '../../api/NetCashAPI';

import useNetCash from '../../hooks/useNetCash';

import { INetCash } from "./INetCash";

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './NetCash.scss';

const NetCash = ({ baseUrl, getAuthToken, showServiceFeedbackModal }: INetCash) => {
	const netCashAPI = NetCashAPI({
		baseUrl, getAuthToken, showServiceFeedbackModal
	});
	
	const {
		funds,
		data,
		handleSaveChanges
	} = useNetCash({ api: netCashAPI });

	return (
		<div className="net-cash">
			<h3>NET CASH FOR REPORTING</h3>
			<DataGrid
				dataSource={data}
				keyExpr="id"
				showBorders
				showColumnHeaders
				showColumnLines
				showRowLines
				onSaving={handleSaveChanges}
			>
				<Editing
					mode="batch"
					allowAdding
					allowUpdating
					allowDeleting
					useIcons={true}
				/>
				<Column dataField="date" caption="Date" dataType="date" format="dd/MM/yyyy">
					<RequiredRule/>
				</Column>
				<Column dataField="amount" caption="Amount" dataType="number" format="€ #,##0.00"/>
				<Column dataField="fxAmount" caption="FxAmount" dataType="number" format="Fx #,##0.00"/>
				<Column dataField="fund" caption="Fund">
					<RequiredRule/>
					<Lookup dataSource={funds} valueExpr="name" displayExpr="name"/>
				</Column>
			</DataGrid>
		</div>
	);
};

export default NetCash;