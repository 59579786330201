import { IReportHeader } from './IReportHeader'
import './ReportHeader.scss'
const ReportHeader =  ({ programName, date, section, confidential, page }: IReportHeader) => {
  return (
    <div className="report-header">
        <p className="confidential-text">{confidential}</p>
        <p className="page">{page}</p>
        <img
            src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/reporting/new_logo.png"
            alt="logo qualitas"
            className="report-logoQualitas"
        />
        <div className="report-header-text">
        <p>
            {programName}. <strong>{date}</strong>.
            <span> {section} </span>
        </p>
        </div>
    </div>

  )
}

export default ReportHeader