import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP1Es = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENCIAL"
      date={date}
      section="Glosario de términos"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <p className="glossary-content-title">Glosario de términos</p>
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">202X A</p>
          <p className="glossary-term-item">
            Datos financieros reales de ese año.
          </p>
          <p className="glossary-term-item-bold">202X B</p>
          <p className="glossary-term-item">
            Datos financieros presupuestados para ese año.
          </p>
          <p className="glossary-term-item-bold">Add-on</p>
          <p className="glossary-term-item">
            Adquisiciones de compañías que se realizan para integrar con otra
            compañía, incrementando su actividad y tamaño.
          </p>
          <p className="glossary-term-item-bold">Apalancamiento</p>
          <p className="glossary-term-item">
            Nivel de endeudamiento de una inversión, generalmente medido como un
            múltiplo sobre EBITDA (Deuda / EBITDA).
          </p>
          <p className="glossary-term-item-bold">Benelux</p>
          <p className="glossary-term-item">
            Bélgica, Países Bajos y Luxemburgo.
          </p>
          <p className="glossary-term-item-bold">Buy & build</p>
          <p className="glossary-term-item">
            Estrategia de inversión que implica la adquisición inicial de una
            empresa establecida y bien posicionada, a partir de la cual se van
            añadiendo empresas más pequeñas para crear un grupo más grande y con
            mayor crecimiento.
          </p>
          <p className="glossary-term-item-bold">Buyout</p>
          <p className="glossary-term-item">
            Estrategia de inversión que se enfoca en adquirir empresas maduras
            que generas beneficios, con niveles de deuda sostenible y un negocio
            consolidado. El fondo a menudo usa deuda para financiar la compra,
            busca ineficiencias para hacer mejoras operativas y financieras, y
            luego venderla.
          </p>
          <p className="glossary-term-item-bold">Capex</p>
          <p className="glossary-term-item">
            “Capital expenditure”. Gastos realizados por una empresa para
            adquirir, mantener o mejorar activos físicos, como edificios,
            maquinaria, equipo o tecnología
          </p>
          <p className="glossary-term-item-bold">CEO</p>
          <p className="glossary-term-item">
            “Chief Executive Officer” o director ejecutivo.
          </p>
          <p className="glossary-term-item-bold">CFO</p>
          <p className="glossary-term-item">
            “Chief Financial Officer” o director financiero.
          </p>
          <p className="glossary-term-item-bold">CHF</p>
          <p className="glossary-term-item">Franco suizo.</p>
          <p className="glossary-term-item-bold">Coinversión</p>
          <p className="glossary-term-item">
            Inversión directa en un activo o compañía de la mano de un gestor de
            private equity que adquiere una posición de control. Los gestores
            suelen invitar a coinvertir cuando el tamaño de la transacción es
            demasiado grande para su fondo.
          </p>
          <p className="glossary-term-item-bold">Compromiso</p>
          <p className="glossary-term-item">
            Cantidad de dinero que un inversor se compromete a invertir en el
            fondo.
          </p>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP1Es;
