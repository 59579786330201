import { AxiosResponse } from 'axios';
import API from './api';
import { notification } from 'antd';

const UpdateQuarterApi = ({ baseUrl, getAuthToken }: any) => {
    const api = API(baseUrl!, getAuthToken);

    const generateFundFigures = (quarterDate: string, idPeip: number) => {
		const serviceUrl = `/investorportal-api/FundFigures/GenerateQuarterFundFigures?IdPeip=${idPeip}&Date=${quarterDate}`;
		return api.get(serviceUrl)
		.then((response: AxiosResponse) => response.data)
		.catch((error) => notification.error({ message: `Error with ${baseUrl}${serviceUrl}` }));
    };

    return {
        generateFundFigures,
    };
};

export default UpdateQuarterApi;