import { ICompaniesList } from "./ICompaniesList";

import "./CompaniesList.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";
import { Col, Row } from "antd";
import { GlobalOutlined } from "@ant-design/icons";

const CompaniesList = ({ templateData, translations, formatNumber }: ICompaniesList) => {

  let groups: any[] = []
  
  let groupsCopy = [...groups];
  if (templateData.companiesList) {
    templateData.companiesList.forEach((item: any, index: any) => {
      const groupIndex = Math.floor(index / 4);
      if (!groupsCopy[groupIndex]) {
        groupsCopy[groupIndex] = [];
      }
      groupsCopy[groupIndex].push(item);
    });
  }
  groups = groupsCopy;

  let page = templateData.page - 1;

  const capitalizeFirstLetter = (dateString: string) => {
    return dateString.charAt(0).toUpperCase() + dateString.slice(1);
  }

  return (
    <>
      {groups.map((group: any) => {
        page++;
        return (
          <div id="companiesList" contentEditable className="pagebreak">
            <ReportHeader
              confidential={translations.confidential}
              programName={templateData.programName}
              date={`${translations[templateData.month]} ${templateData.year}`}
              section={translations.section}
              page={page}
            />
            <Row className="content" align="top" gutter={[0, 0]}>
              <Col span={24}>
                <main className="companiesList">
                  <p className="companiesList-subtitle">
                    {translations.section}
                  </p>
                  <section className="companiesList-content">
                    {group.map((item: any, index: number) => (
                      <div className="companiesList-item" key={index}>
                        <p className="companiesList-title">
                          {item.name}
                          <span>
                            {item.country} | {item.type}
                          </span>
                        </p>
                        <div className="companiesList-headerInfo">
                          <div className="companiesList-headerInfo-item">
                            <p>{translations.underlying}</p>
                            <p>{item.underlyingFund}</p>
                          </div>
                          <div className="companiesList-headerInfo-item">
                            <p>{translations.fundInvestment}</p>
                            <p>€{formatNumber(item.qfund, 1)}M</p>
                          </div>
                          <div className="companiesList-headerInfo-item">
                            <p>MOIC</p>
                            <p>{formatNumber(item.moc)}x</p>
                          </div>
                          <div className="companiesList-headerInfo-item">
                            <p>{translations.date}</p>
                            <p>{capitalizeFirstLetter(item.investmentDate)}</p>
                          </div>
                          <div className="companiesList-headerInfo-item">
                            <p>{translations.exitDate}</p>
                            <p>{item.exitDate}</p>
                          </div>
                          <div className="companiesList-headerInfo-item">
                            <p>{translations.stage}</p>
                            <p>{item.stage}</p>
                          </div>
                          <div className="companiesList-headerInfo-item">
							{item.logo ? 
								<img
								className="companiesList-logo"
								src={item.logo}
								alt="logo"
								/>
							: <></>}
                          </div>
                        </div>
                        <div className="companiesList-info">
                          <div className="companiesList-info-text">
                            <div  dangerouslySetInnerHTML={{ __html: item.description }}>
                            </div>
                            {item.url !== "" &&
                              <p>
                                <GlobalOutlined
                                  style={{
                                    fontSize: 13,
                                    color: "grey",
                                    fontWeight: 100,
                                  }}
                                  />
                                {' '}<a href={item.url}>{item.url}</a>
                              </p>
                            }
                          </div>
                        </div>
                      </div>
                    ))}
                  </section>
                </main>
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default CompaniesList;
