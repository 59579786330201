import { Col, Row } from "antd";

import "./Index.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";
import { IIndex } from "./IIndex";

const Index = ({ templateData, translations }: IIndex) => {
	let page = 3; 

	return(
	<div id="index" contentEditable className="pagebreak">
		<ReportHeader
			programName={templateData.programName}
			confidential={translations.confidential}
			date={`${translations[templateData.month]} ${templateData.year}`}
			section={translations.section}
			page={templateData.page}
		/>
		<Row align="middle">
			<Col>
				<main className="index">
					<section className="index-content">
						<p className="index-title">{translations.content}</p>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>1. {translations.theFund}</Col>
							<Col span={1}>{page}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>2. {translations.mainFigures}</Col>
							<Col span={1}>{page = page + templateData.section1Pages}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>3. {translations.activityOfTheFund}</Col>
							<Col span={1}>{page = page + templateData.section2Pages}</Col>
							<Col span={23} className="index-subSection" >3.1 {translations.portfolio}</Col>
							<Col span={1}>{page}</Col>
							<Col span={23} className="index-subSection" >3.2 {translations.valuation}</Col>
							<Col span={1}>{page}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>4. {translations.diversificationAnalysis}</Col>
							<Col span={1}>{page = page + templateData.section3Pages}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>5. {translations.portfolioPerformance}</Col>
							<Col span={1}>{page = page + templateData.section4Pages}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>6. {translations.portfolioCompanies}</Col>
							<Col span={1}>{page = page + templateData.section5Pages}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>7. {translations.recentPortfolioCompanies}</Col>
							<Col span={1}>{page = page + templateData.section6Pages}</Col>
						</Row>
						<Row className="index-section" gutter={[0,5]}>
							<Col span={23}>8. {translations.termsGlossary}</Col>
							<Col span={1}>{page = page + templateData.section7Pages}</Col>
						</Row>
					</section>
				</main>
			</Col>
		</Row>
	</div>
)};

export default Index;