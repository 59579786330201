import { notification } from "antd"
import { useEffect, useState } from "react"
import CommonApi from "../api/CommonApi";

const useApp = (props: any) => {
	const { baseUrl, getAuthToken } = props
	const commonApi = CommonApi({ baseUrl, getAuthToken});
	const [peipList, setPeipList] = useState<any[]>([])

	useEffect(() => {
		getPeips()
	}, [])

	const getPeips = async() => {
		await commonApi.getPeips().then((res:any) => {
			if(res){
				setPeipList(res)
			}else{
				notification.error({ message: 'Error retrieving Peips' })
			}
		})
	}

  return {
	peipList
  }
}

export default useApp
