import { notification } from 'antd';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const useUpdatesQuarter = (props: any) => {
	const { setIsUpdatesModalOpen, updatesApi, onSelect} = props
	const [currentStep, setCurrentStep] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false)
	const [ selectedPeip, setSelectedPeip ] = useState<string | undefined>(undefined)
	const [quartersList, setQuartersList] = useState<any[]>([])
	const [selectedQuarter, setSelectedQuarter] = useState<string | undefined>(undefined)

	const navigate = useNavigate();

	useEffect(() => {
		fillQuarters()
	}, [])

	const generateFundFigures = () => {
		setLoading(true)
		try{
			updatesApi.generateFundFigures(selectedQuarter, selectedPeip).then((res: any) => {
				if(res.success){
					notification.success({ message: 'Fund Figures generated successfully'})
				}else{
					notification.error({ message: 'Error generating Fund Figures'})							
				}
				setLoading(false)	
			})
		}
		catch{
			notification.error({ message: 'Error generating Fund Figures'})	
			setLoading(false)
		}
	} 
	
	const closeUpdatesModal = () => {
		setCurrentStep(0)
		setIsUpdatesModalOpen(false)
	}

	const onChangeSelectedPeip = (value: string) => {
		setSelectedPeip(value)
	}

	const goToNetCash = () => {
		onSelect({ key: '3' })
		navigate('/netCash')
		closeUpdatesModal()
	}

	const fillQuarters = () => {
		const dates = ['03-31', '06-30', '09-30', '12-31']
		const currentYear = new Date().getFullYear()
		const lastYear = currentYear - 1
		const quarters = []
		for (let i = 0; i < 2; i++) {
			for (let j = 0; j < 4; j++) {
				quarters.push({
					value: `${i === 0 ? lastYear : currentYear}-${dates[j]}`,
					label: ` Q${j + 1} ${i === 0 ? lastYear : currentYear}`
				})
			}
		}
		setQuartersList(quarters)
	}

	const onChangeQuarter = (value: string) => {
		setSelectedQuarter(value)
	}



  return {
	closeUpdatesModal,
	currentStep, 
	setCurrentStep,
	generateFundFigures,
	goToNetCash,
	selectedPeip,
	onChangeSelectedPeip,
	quartersList,
	onChangeQuarter,
	selectedQuarter,
	loading
  }
}

export default useUpdatesQuarter