import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP3En = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENTIAL"
      date={date}
      section="Glossary"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">Leverage</p>
          <p className="glossary-term-item">
            Level of debt of an investment, generally measured as a multiple
            over EBITDA (Debt/EBITDA).
          </p>
          <p className="glossary-term-item-bold">LTM</p>
          <p className="glossary-term-item">Last Twelve Months.</p>
          <p className="glossary-term-item-bold">M</p>
          <p className="glossary-term-item">Millions</p>
          <p className="glossary-term-item-bold">MOIC</p>
          <div className="glossary-term-item-special">
            <p className="glossary-term-item-special-text">
              Gross multiple on Invested Capital, calculated as:
            </p>
            <div className="glossary-term-item-form">
              <p>MOIC =</p>
              <div className="centered">
                <p className="glossary-term-item-lined">
                  Value of an investment
                </p>
                <p>Total cost of the investments</p>
              </div>
            </div>
          </div>
          <p className="glossary-term-item-bold">NAV</p>
          <p className="glossary-term-item">
            Net Asset Value. Refers to the actual value of an investment at a
            given time.
          </p>
          <p className="glossary-term-item-bold">NOK</p>
          <p className="glossary-term-item">Norwegian krone.</p>
          <p className="glossary-term-item-bold">p.a.</p>
          <p className="glossary-term-item">Per annum. Annually</p>
          <p className="glossary-term-item-bold">Private equity</p>
          <p className="glossary-term-item">
            Taking majority or minority interests in unlisted companies, for a
            period that allows for the execution of a business plan established
            at acquisition, to obtain profits when the company is sold.
          </p>
          <p className="glossary-term-item-bold">Proforma</p>
          <p className="glossary-term-item">
            Amount reflects transactions carried out after the referral
            valuation date.
          </p>
          <p className="glossary-term-item-bold">Q1</p>
          <p className="glossary-term-item">
            First quarter of the year ending on March 31st.
          </p>
          <p className="glossary-term-item-bold">Q2</p>
          <p className="glossary-term-item">
            Second quarter of the year ending on June 30th.
          </p>
          <p className="glossary-term-item-bold">Q3</p>
          <p className="glossary-term-item">
            Third quarter of the year ending on September 30th.
          </p>
          <p className="glossary-term-item-bold">Q4</p>
          <p className="glossary-term-item">
            Fourth quarter of the year ending on December 31st.
          </p>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP3En;
