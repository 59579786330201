import { AxiosResponse } from 'axios';
import API from './api';
import { notification } from 'antd';

const ProceduresAPI = ({ baseUrl, getAuthToken }: any) => {
    const api = API(baseUrl!, getAuthToken);

    const executeProcedures = (quarterDate: string, procedureName: string) => {
		const serviceUrl = `/reporting-api/FoFReport/ExecQuarterlyReportStoredProcedures?date=${quarterDate}&procedureName=${procedureName}`;
		return api.get(serviceUrl)
			.then((response: AxiosResponse) => response.data)
			.catch((error) => notification.error({ message: `Error with ${baseUrl}${serviceUrl}` }));
    };

	const getPeips = () => {
		const serviceUrl = '/masters-api/Peips';
		return api.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
			.catch((error) => notification.error({ message: `Error with ${baseUrl}${serviceUrl}` }));
	};

	const generateTires = (quarterDate: string, idPeip: number) => {
		const serviceUrl = `/reporting-api/Quarterly/GenerateTrim_Tires?IdPeip=${idPeip}&date=${quarterDate}`;
		return api.get(serviceUrl)
			.then((response: AxiosResponse) => response.data)
			.catch((error) => notification.error({ message: `Error with ${baseUrl}${serviceUrl}` }));
    };

	const checkTiresStatus = () => {
		const serviceUrl = '/reporting-api/Quarterly/GetTrimTiresGenerationStatus';
		return api.get(serviceUrl)
			.then((response: AxiosResponse) => response.data)
			.catch((error) => notification.error({ message: `Error with ${baseUrl}${serviceUrl}` }));
	};

	const generateFundFigures = (idPeip: number,quarterDate: string) => {
		const serviceUrl = `/investorportal-api/FundFigures/GenerateQuarterFundFigures?IdPeip=${idPeip}&Date=${quarterDate}`;
		return api.get(serviceUrl)
			.then((response: AxiosResponse) => response.data)
			.catch((error) => notification.error({ message: `Error with ${baseUrl}${serviceUrl}` }));
    };

    return {
        executeProcedures,
		getPeips,
		generateTires,
		checkTiresStatus,
		generateFundFigures
    };
};

export default ProceduresAPI;