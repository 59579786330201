import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP2En = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENTIAL"
      date={date}
      section="Glossary"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">DACH</p>
          <p className="glossary-term-item">
            Germany, Austria, and Switzerland.
          </p>
          <p className="glossary-term-item-bold">Distributions</p>
          <p className="glossary-term-item">
            Income received by the investor during the fund's divestment period.
            Distributions are equal to the sum of the capital invested in the
            fund plus capital gains.
          </p>
          <p className="glossary-term-item-bold">DKK</p>
          <p className="glossary-term-item">Danish krone.</p>
          <p className="glossary-term-item-bold">DPI</p>
          <div className="glossary-term-item-special">
            <p className="glossary-term-item-special-text">
              Distributed to Paid-In Capital. Distributions received divided by
              paid-in capital, calculated as:
            </p>
            <div className="glossary-term-item-form">
              <p>DPI =</p>
              <div className="centered">
                <p className="glossary-term-item-lined">
                  Distributions received
                </p>
                <p>Total paid-in capital</p>
              </div>
            </div>
          </div>
          <p className="glossary-term-item-bold">EBITDA</p>
          <p className="glossary-term-item">
            Earnings Before Interest, Taxes, Depreciation and Amortization.
          </p>
          <p className="glossary-term-item-bold">EUR</p>
          <p className="glossary-term-item">Euro.</p>
          <p className="glossary-term-item-bold">EV</p>
          <p className="glossary-term-item">
            Enterprise Value, including both equity and debt value.
          </p>
          <p className="glossary-term-item-bold">GBP</p>
          <p className="glossary-term-item">British pound sterling.</p>
          <p className="glossary-term-item-bold">GP</p>
          <p className="glossary-term-item">
            General Partner. Management company of a private equity fund.
          </p>
          <p className="glossary-term-item-bold">Growth</p>
          <p className="glossary-term-item">
            Investment strategy where a fund provides capital to growing
            companies in exchange for a stake in the company to finance the
            company's expansion plans.
          </p>
          <p className="glossary-term-item-bold">Investment commitment</p>
          <p className="glossary-term-item">
            Maximum amount that an investor in a Fund or FoFs commits to be
            drawn down over time through Capital Calls.
          </p>
          <p className="glossary-term-item-bold">IRR</p>
          <p className="glossary-term-item">
            Internal Rate of Return. It measures the annualized return on an
            investment that has negative and positive cash flows (capital calls
            and distributions) through the investment’s holding period.
          </p>
          <p className="glossary-term-item-bold">IT</p>
          <p className="glossary-term-item">Information technology.</p>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP2En;
