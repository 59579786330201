import { Col, Row } from "antd";
import dayjs from "dayjs";

import { IFundSummaryFoF } from "./IFundSummaryFoF";

import "./FundSummaryFoF.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";

const FundSummaryFoF = ({ templateData, translations, formatNumber }: IFundSummaryFoF) => (
  <div id="fundSummary" contentEditable className='pagebreak'>
    <ReportHeader
      confidential={translations.confidential}
      programName={templateData.programName}
      date={`${translations[templateData.month]} ${templateData.year}`}
      section={translations.section}
      page={templateData.page}
    />
    <Row align="middle">
      <Col>
        <main className="fundSummary">
          <section className="fundSummary-content">
            {/* ROW 1 */}
            <div className="fundSummary-row-space">
              <p className="fundSummary-subtitle">{translations.subtitle1}</p>
              <div className="fundSummary-content-row">
                <div className="fundSummary-box">
                  <p className="fundSummary-box-text">
                    <span>€</span>
                    {formatNumber(templateData["01size"], 1)}
                    <span>M</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle1Box1Subtext1}
                  </p>
                  <p className="fundSummary-box-text fundSummary-divider">
                    {templateData["01closed"]}
                    <span>%</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle1Box1Subtext2}
                  </p>
                </div>
                <div className="fundSummary-box">
                  <p className="fundSummary-box-text">
                    <span>€</span>
                    {formatNumber(templateData["01drawdowns"], 1)}
                    <span>M</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle1Box2Subtext1}
                  </p>
                  <p className="fundSummary-box-text fundSummary-divider">
                    {formatNumber(templateData["01onCommitment"], 0)}
                    <span>%</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle1Box2Subtext2}
                  </p>
                </div>
                <div className="fundSummary-box">
                  <p className="fundSummary-box-text">
                    <span>€</span>
                    {formatNumber(templateData["01distributed"], 1)}
                    <span>M</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle1Box3Subtext1}
                  </p>
                  <p className="fundSummary-box-text fundSummary-divider">
                    {formatNumber(templateData["01onDrawdown"], 0)}
                    <span>%</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle1Box3Subtext2}
                  </p>
                </div>
                <div className="fundSummary-box">
                  <p className="fundSummary-box-text">
                    {formatNumber(templateData["01tvpi"])}
                    <span>x</span>
                  </p>
                  <p className="fundSummary-box-subtext">TVPI</p>
                  <p className="fundSummary-box-text fundSummary-divider">
                    {formatNumber(templateData["01tir"], 1)}
                    <span>%</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle1Box4Subtext2}
                  </p>
                </div>
              </div>
            </div>
            {/* ROW 2 */}
            <div className="fundSummary-row-space">
              <p className="fundSummary-subtitle">{translations.subtitle2}</p>
              <div className="fundSummary-content-row">
                <div className="fundSummary-box">
                  <p className="fundSummary-box-text">
                    {formatNumber(templateData["02mocGross"])}
                    <span>x</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle2Box1Subtext1}
                  </p>
                  <p className="fundSummary-box-text fundSummary-divider">
                    {formatNumber(templateData["02vsLast"])}
                    <span>x</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    Vs. {templateData["02lastDate"]}
                  </p>
                </div>
                <div className="fundSummary-box">
                  <p className="fundSummary-box-text">
                    <span>€</span>
                    {formatNumber(templateData["02commitment"],1)}
                    <span>M</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle2Box2Subtext1}
                  </p>
                  <p className="fundSummary-box-text fundSummary-divider">
                    {templateData["02onFundSize"]}
                    <span>%</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle2Box2Subtext2}
                  </p>
                </div>
                <div className="fundSummary-box">
                  <p className="fundSummary-box-text">
                    <span>€</span>
                    {formatNumber(templateData["02drawdown"],1)}
                    <span>M</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle2Box3Subtext1} 
                  </p>
                  <p className="fundSummary-box-text fundSummary-divider">
                    {templateData["02onCommitment"]}
                    <span>%</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle2Box3Subtext2}
                  </p>
                </div>
                <div className="fundSummary-box">
                  <p className="fundSummary-box-text">
                    <span>€</span>
                    {formatNumber(templateData["02distributed"],1)}
                    <span>M</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle2Box4Subtext1}
                  </p>
                  <p className="fundSummary-box-text fundSummary-divider">
                    {templateData["02onDrawdown"]}
                    <span>%</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle2Box4Subtext2}
                  </p>
                </div>
              </div>
            </div>
            {/* ROW 3 */}
            <div className="fundSummary-row-space">
              <p className="fundSummary-subtitle">{translations.subtitle3}</p>
              <div className="fundSummary-content-row">
                <div className="fundSummary-box">
                  <p className="fundSummary-box-text">
                    {formatNumber(templateData["03grossTir"],1)}
                    <span>%</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle3Box1Subtext1}
                  </p>
                  <p className="fundSummary-box-text fundSummary-divider">
                    {formatNumber(templateData["03grossMoic"])}
                    <span>x</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle3Box1Subtext2}
                  </p>
                </div>
                <div className="fundSummary-box">
                  <p className="fundSummary-box-text">
                    <span>€</span>
                    {formatNumber(templateData["03newCommitments"],1)}
                    <span>M</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle3Box2Subtext1}
                  </p>
                  <p className="fundSummary-box-text fundSummary-divider">
                    {templateData["03onFundSize"]}
                    <span>%</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle3Box2Subtext2}
                  </p>
                </div>
                <div className="fundSummary-box">
                  <p className="fundSummary-box-text">
                    <span>€</span>
                    {formatNumber(templateData["03drawdown"],1)}
                    <span>M</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle3Box3Subtext1}
                  </p>
                  <p className="fundSummary-box-text fundSummary-divider">
                    {templateData["03onTotalDradown"]}
                    <span>%</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle3Box3Subtext2}
                  </p>
                </div>
                <div className="fundSummary-box">
                  <p className="fundSummary-box-text">
                    <span>€</span>
                    {formatNumber(templateData["03distributed"],1)}
                    <span>M</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle3Box4Subtext1}
                  </p>
                  <p className="fundSummary-box-text fundSummary-divider">
                    {templateData["03onDrawdown"]}
                    <span>%</span>
                  </p>
                  <p className="fundSummary-box-subtext">
                    {translations.subtitle3Box4Subtext2}
                  </p>
                </div>
              </div>
            </div>
            {/* ROW 4 */}
            <div className="fundSummary-row-space">
              <p className="fundSummary-subtitle">{translations.subtitle4}</p>
              <div className="fundSummary-content-row">
                <div className="fundSummary-box box4">
                  <div className="fundSummary-summary">
                    <div className="fundSummary-summary-section">
                      <p className="fundSummary-box-text">
                        {templateData["04gps"]}
                      </p>
                      <p className="fundSummary-box-subtext">
                        {translations.subtitle4Box1Subtext1}
                      </p>
                    </div>
                    <div className="fundSummary-summary-section">
                      <p className="fundSummary-box-text">
                        {templateData["04funds"]}
                      </p>
                      <p className="fundSummary-box-subtext">
                        {translations.subtitle4Box1Subtext3}
                      </p>
                    </div>
                    <div className="fundSummary-summary-section">
                      <p className="fundSummary-box-text">
                        {templateData["04coinv"]}
                      </p>
                      <p className="fundSummary-box-subtext">
                        {translations.subtitle4Box1Subtext2}
                      </p>
                    </div>
                  </div>
                  <p className="fundSummary-summary-header">
                    {translations.subtitle4Box1Header}
                  </p>
                  <div className="fundSummary-summary summary-section2">
                    <div className="fundSummary-summary-section">
                      <p className="fundSummary-box-text">
                        {templateData["04periodGps"]}
                      </p>
                      <p className="fundSummary-box-subtext">
                        {translations.subtitle4Box1Subtext1}
                      </p>
                    </div>
                    <div className="fundSummary-summary-section">
                      <p className="fundSummary-box-text">
                        {templateData["04periodFunds"]}
                      </p>
                      <p className="fundSummary-box-subtext">
                        {translations.subtitle4Box1Subtext3}
                      </p>
                    </div>
                    <div className="fundSummary-summary-section">
                      <p className="fundSummary-box-text">
                        {templateData["04periodCoinv"]}
                      </p>
                      <p className="fundSummary-box-subtext">
                        {translations.subtitle4Box1Subtext2}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="fundSummary-box box4">
                  <p className="fundSummary-box-text">
                    {templateData["04companies"]}
                  </p>
                  <p className="fundSummary-box-subtext subtext-space">
                    {translations.subtitle4Box2Subtext2
                      .replace("[04regions]", templateData["04regions"])
                      .replace("[04sectors]", templateData["04sectors"])}
                  </p>
                </div>
                <div className="fundSummary-box box4">
                  <p className="fundSummary-box-text">
                    {formatNumber(templateData["04avarageMoc"])}
                    <span>x</span>
                  </p>
                  <p className="fundSummary-box-subtext subtext-space">
                    {translations.subtitle4Box3Subtext1}
                  </p>
                </div>
                <div className="fundSummary-box box4">
                  <p className="fundSummary-box-text">
                    <span>€</span>
                    {formatNumber(templateData["04averageValue"], 1)}
                    <span>M</span>
                  </p>
                  <p className="fundSummary-box-subtext subtext-space">
                    {translations.subtitle4Box4Subtext1}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Col>
    </Row>
  </div>
);

export default FundSummaryFoF;
