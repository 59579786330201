import { Col, Row } from "antd";
import { IPortfolioBreakdown } from "./IPortfolioBreakdown";
import ReportHeader from "../../Common/00-Header/ReportHeader";
import "./PortfolioBreakdown.scss";


const PortfolioBreakdown = ({templateData, translations, formatNumber}: IPortfolioBreakdown) => {
	
	const renderTable = (lines: any, translations: any) => (
	  <table className="portfolioBreakdown-analysis-table">
		<colgroup>
		  <col style={{ width: "40px" }} />
		  <col style={{ width: "100px" }} />
		  <col style={{ width: "90px" }} />
		  <col style={{ width: "85px" }} />
		  <col style={{ width: "60px" }} />
		  <col style={{ width: "55px" }} />
		  <col style={{ width: "55px" }} />
		  <col style={{ width: "60px" }} />
		  <col style={{ width: "90px" }} />
		  <col style={{ width: "50px" }} />
		</colgroup>
		<thead>
		  <tr>
			<th>{translations.state}*</th>
			<th>{translations.name}</th>
			<th>{translations.country}</th>
			<th>{translations.ev}</th>
			<th>{translations.dateInv}</th>
			<th className="portfolioBreakdown-analysis-grey">
			  {translations.holdingPeriod}
			</th>
			<th className="portfolioBreakdown-analysis-grey">
			  {translations.investment}
			</th>
			<th className="portfolioBreakdown-analysis-grey">% {translations.fund}</th>
			<th className="portfolioBreakdown-analysis-grey">MOIC **</th>
			<th className="portfolioBreakdown-analysis-grey">
			  {translations.opinion}
			</th>
		  </tr>
		</thead>
		<tbody>
		  {lines.map((row: any, index: number) => {
			let maxWidth = 0;
			for (const item of lines) {
			  if (item.residual + item.distribution > maxWidth) {
				maxWidth = item.residual + item.distribution;
			  }
			}
			let width = ((row.distribution + row.residual) * 100) / maxWidth;
			let distPercentage =
			  (((row.distribution * 100) / (row.distribution + row.residual)) *
				width) /
			  100;
			return (
			  <tr key={index}>
				<td>{row.state}</td>
				<td>{row.name}</td>
				<td className="portfolioBreakdown-analysis-small">
				  {row.country} <br /> {row.activity}
				</td>
				<td>{row.ev}</td>
				<td>{row.date}</td>
				<td className="portfolioBreakdown-analysis-grey">
				  {formatNumber(row.holdingPeriod, 1)} {translations.years}
				</td>
				<td className="portfolioBreakdown-analysis-grey">
				  €{formatNumber(row.investment, 1)}M
				</td>
				<td className="portfolioBreakdown-analysis-grey">
				  {formatNumber(row.qfPercentage)}%
				</td>
				<td className="portfolioBreakdown-analysis-grey">
				  <div
					className="progress-bar"
					style={{
					  background: `linear-gradient(to right, #c4c4c4 ${distPercentage}%, #fff ${distPercentage}%, #fff ${width}%,#f0f0f0 ${width}%)`,
					}}
				  >
					<span>{formatNumber(row.moc, 2)}x</span>
				  </div>
				</td>
				<td className="portfolioBreakdown-analysis-grey">
				  <div className={`manager-opinion opt-${row.opinion}`} />
				</td>
			  </tr>
			);
		  })}
		</tbody>
	  </table>
	);
	
	const splitPages = (templateData: any, rowsPerPage: any = 19) => {
	  let pages = [];
	  let currentPage: any = [];
	  let currentRowCount = 0;
	
	  templateData.forEach((fund: any) => {
		let fundRows = fund.companies.length + 2; // +1 for the title +1 for the header
		if (currentRowCount + fundRows <= rowsPerPage) {
		  currentPage.push({ fundName: fund.fund, rows: fund.companies });
		  currentRowCount += fundRows;
		} else {
		  // Add remaining rows in the current page
		  let remainingRows = rowsPerPage - currentRowCount;
		  let remainingCompanies = fund.companies.slice(
			0,
			remainingRows - 1 < 0 ? 0 : remainingRows - 1
		  );
	
		  if (remainingCompanies.length > 0) {
			currentPage.push({ fundName: fund.fund, rows: remainingCompanies });
		  }
	
		  pages.push(currentPage);
		  currentPage = [];
		  currentRowCount = 0;
	
		  // Continue adding the remaining rows to the next page
		  let remainingFundRows = fund.companies.slice(
			remainingRows - 1 < 0 ? 0 : remainingRows - 1
		  );
		  while (remainingFundRows.length > 0) {
			let rowsForNextPage = remainingFundRows.slice(0, rowsPerPage);
			remainingFundRows = remainingFundRows.slice(rowsPerPage);
			currentPage.push({ fundName: fund.fund, rows: rowsForNextPage });
	
			if (remainingFundRows.length > 0) {
			  pages.push(currentPage);
			  currentPage = [];
			} else {
			  currentRowCount += rowsForNextPage.length + 2;
			  break;
			}
	
			//currentPage.push({ fundName: "", rows: rowsForNextPage }); // No title for subsequent pages of the same fund
		  }
		}
	  });
	
	  if (currentPage.length > 0) {
		pages.push(currentPage);
	  }
	  return pages;
	};
  const pages = splitPages(templateData.portfolioBreakdown);
  let page = templateData.page - 1;
  return (
    <>
      {pages.map((pageContent, pageIndex) => {
        page++;
        return (
          <div
            id="portfolioBreakdown"
            contentEditable
            key={pageIndex}
            className="pagebreak"
          >
            <ReportHeader
              confidential={translations.confidential}
              programName={templateData.programName}
              date={`${translations[templateData.month]} ${templateData.year}`}
              section={translations.section}
              page={page}
            />
            <Row className="content" align="top" gutter={[0, 0]}>
              <Col span={24}>
                <main className="portfolioBreakdown">
                  <section className="portfolioBreakdown-content">
                    <p className="portfolioBreakdown-subtitle">
                      {translations.section}
                    </p>
                    {pageContent.map((content: any, index: number) => (
                      <div className="portfolioBreakdown-analysis" key={index}>
                        {content.fundName && <p>{content.fundName}</p>}
                        {renderTable(content.rows, translations)}
                      </div>
                    ))}
                    <div className="portfolioBreakdown-analysis-references">
                      <div>
                        <span>{translations.ref1}</span>
                        <br />
                        <div className="portfolioFunds-analysis-references-moc">
                          <div className="moc-reference ref-1" />
                          {translations.ref2}
                          <div className="moc-reference ref-2" />
                          {translations.ref3}
                        </div>
                      </div>
                      <div className="portfolioBreakdown-analysis-ref-opinions">
                        <div className="portfolioBreakdown-analysis-ref-opinion-item">
                          <span>{translations.option1}</span>
                          <div className="manager-opinion opt-1" />
                        </div>
                        <div className="portfolioBreakdown-analysis-ref-opinion-item">
                          <span>{translations.option2}</span>
                          <div className="manager-opinion opt-2" />
                        </div>
                        <div className="portfolioBreakdown-analysis-ref-opinion-item">
                          <span>{translations.option3}</span>
                          <div className="manager-opinion opt-3" />
                        </div>
                        <div className="portfolioBreakdown-analysis-ref-opinion-item">
                          <span>{translations.option4}</span>
                          <div className="manager-opinion opt-4" />
                        </div>
                      </div>
                    </div>
                  </section>
                </main>
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default PortfolioBreakdown;
