import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP4En = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENTIAL"
      date={date}
      section="Glossary"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">RVPI or RPI</p>
          <div className="glossary-term-item-special">
            <p className="glossary-term-item-special-text">
              Residual Value to Paid-In Capital. Ratio of the remaining value of
              a fund's portfolio to the total amount of capital contributed by
              investors, calculated as:
            </p>
            <div className="glossary-term-item-form">
              <p>RVPI =</p>
              <div className="centered">
                <p className="glossary-term-item-lined">
                  NAV * Number of outstanding shares
                </p>
                <p>Total paid-in capital</p>
              </div>
            </div>
          </div>
          <p className="glossary-term-item-bold">SEK</p>
          <p className="glossary-term-item">Swedish krona. </p>

          <p className="glossary-term-item-bold">TVPI</p>
          <div className="glossary-term-item-special">
            <p className="glossary-term-item-special-text">
              Total Value to Paid-In Capital. Represents the current value of
              the fund's investments, both realized and unrealized, in relation
              to the capital disbursed by investors, calculated as:{" "}
            </p>
            <div className="glossary-term-item-form">
              <p>TVPI =</p>
              <div className="centered">
                <p className="glossary-term-item-lined">
                  Total portfolio value
                </p>
                <p>Total paid-in capital</p>
              </div>
            </div>
          </div>
          <p className="glossary-term-item-bold">UK</p>
          <p className="glossary-term-item">United Kingdom. </p>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP4En;
