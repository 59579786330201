import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP4Es = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENCIAL"
      date={date}
      section="Glosario de términos"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">SEK</p>
          <p className="glossary-term-item">Corona sueca.</p>
          <p className="glossary-term-item-bold">TI</p>
          <p className="glossary-term-item">Tecnología de la información.</p>
          <p className="glossary-term-item-bold">TIR</p>
          <p className="glossary-term-item">
            Tasa Interna de Retorno. Es una medida de rentabilidad anualizada
            sobre una inversión que implica flujos de caja negativos (como las
            llamadas de capital) y flujos de caja positivos (como las
            distribuciones) a lo largo de los años de tenencia de la inversión.
          </p>
          <p className="glossary-term-item-bold">TVPI</p>
          <div className="glossary-term-item-special">
            <p className="glossary-term-item-special-text">
              "Total Value to Paid-In Capital". Representa el valor actual de
              las inversiones del fondo, tanto realizadas como no realizadas, en
              relación con el capital comprometido por los inversores. Se usa para medir la rentabilidad final de un fondo. Calculado como:
            </p>
            <div className="glossary-term-item-form">
              <p>TVPI =</p>
              <div className="centered">
                <p className="glossary-term-item-lined">
                  Valor actual de las inversiones
                </p>
                <p>Capital comprometido</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP4Es;
