import { Col, Row } from "antd";
import Chart, {
  CommonSeriesSettings,
  Label,
  Series,
} from "devextreme-react/chart";

import { INewInvestments } from "./INewInvestments";
import "./NewInvestments.scss";
import ReportHeader from "../../Common/00-Header/ReportHeader";
import { ArgumentAxis } from "devextreme-react/polar-chart";

const NewInvestments = ({
  templateData,
  translations,
  formatNumber,
}: INewInvestments) => {
  const palette = ["#B3D615", "#8f8f8f", "#ababab"];

  const truncateText = (text: string) => {
    if (text.length > 30) {
      return text.substring(0, 30) + "...";
    } else {
      return text;
    }
  };

  let line = 0;
  const maxLines = 18;
  let outOfBoundElements: any = {
    newInvestments: [],
    newRealizations: [],
  };

  let page = templateData.page;

  const renderPage = (content: any, pageNum: number) => {
    return (
      <div id="newInvestments" contentEditable>
        <ReportHeader
          confidential={translations.confidential}
          programName={templateData.programName}
          date={`${translations[templateData.month]} ${templateData.year}`}
          section={translations.section}
          page={pageNum}
        />
        <Row className="content" align="top" gutter={[0, 0]}>
          <Col span={24}>
            <main className="newInvestments">
              <div className="newInvestments-header">
                <p className="newInvestments-subtitle">
                  {translations.section}
                </p>
                <p>{translations.period}</p>
              </div>
              {content}
            </main>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      {/* Page 1 */}
      {renderPage(
        <>
          <div className="newInvestments-chart">
            <p>
              {translations.investments} vs {translations.distributions}
            </p>
            <div className="newInvestments-chart-section">
              <div>
                <div className="newInvestments-chart-labels">
                  <p>
                    €
                    {formatNumber(
                      Number(templateData.investmentChartLabels.investPrevVal) +
                        Number(
                          templateData.investmentChartLabels.investLastQVal
                        ),
                      1
                    )}
                    M
                  </p>
                  <p>
                    €
                    {formatNumber(
                      Number(templateData.investmentChartLabels.distPrevVal) +
                        Number(templateData.investmentChartLabels.distLastQVal),
                      1
                    )}
                    M
                  </p>
                </div>
                <Chart
                  id="chart"
                  dataSource={templateData.investmentChart}
                  height={110}
                  width={230}
                  palette={palette}
                  legend={{ visible: false }}
                  animation={false}
                  argumentAxis={{
                    visible: true,
					color: '#e6e6e6',
                    label: {
                      overlappingBehavior: "hide",
                      font: { size: 11 },
                    },
                    grid: {
                      visible: false,
                    },
                    tick: { visible: false },
                  }}
                  valueAxis={{
                    visible: false,
                    maxValueMargin: 0.05,
                    grid: {
                      visible: false,
                    },
                    showZero: true,
                    tick: { visible: false },
                    label: {
                      visible: false,
                    },
                  }}
                >
                  <CommonSeriesSettings
                    argumentField="type"
                    type="stackedbar"
                    barWidth={50}
                  />
                  <ArgumentAxis>
                    <Label
                      visible={true}
                      font={{ color: "black", size: "10px" }}
                      customizeText={(e: any) => translations[e.value]}
                    />
                  </ArgumentAxis>
                  <Series valueField="prev" name="RPI" label={{
					visible: true,
					backgroundColor: "none",
					font: { size: 8, color: 'black' },
					position: "inside",
					customizeText: (e: any) => {
						if(e.value > 1) {
							return `€${formatNumber(e.value, 1)}M`
						}
					},
				  }} />
                  <Series valueField="last" name="DPI" label={{
					visible: true,
					backgroundColor: "none",
					font: { size: 8, color: 'black' },
					position: "inside",
					customizeText: (e: any) => {
						if(e.value > 1) {
							return `€${formatNumber(e.value, 1)}M`
						}
					},
				  }}/>
                </Chart>
              </div>
              <div className="newInvestments-chart-legend">
                <div className="newInvestments-chart-legend-item">
                  <div />
                  <p>{translations.previous}</p>
{/*                   <p>
                    €
                    {formatNumber(
                      templateData.investmentChartLabels.distPrevVal,
                      1
                    )}
                    M
                  </p>
                  <span>
                    {templateData.investmentChartLabels.distPrevCompanies}{" "}
                    {translations.companies}
                  </span> */}
                </div>
                <div className="newInvestments-chart-legend-item">
                  <div />
                  <p>{translations.lastQuarter}</p>
                  {/* <p>
                    €
                    {formatNumber(
                      templateData.investmentChartLabels.distLastQVal,
                      1
                    )}
                    M
                  </p>
                  <span>
                    {templateData.investmentChartLabels.distLastQCompanies}{" "}
                    {translations.companies}
                  </span> */}
                </div>
              </div>
            </div>
          </div>
          <section className="newInvestments-content">
            <p>{translations.newInvestments}</p>
			{templateData.newInvestmentsTable.length > 0 ? (

            <table className="newInvestments-table">
              <colgroup>
                <col style={{ width: "150px" }} />
                <col style={{ width: "150px" }} />
                <col style={{ width: "150px" }} />
                <col style={{ width: "150px" }} />
                <col style={{ width: "150px" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>{translations.fund}</th>
                  <th>{translations.name}</th>
                  <th>{translations.countryAndActivity}</th>
                  <th>{translations.entry}</th>
                  <th>{translations.investment}</th>
                </tr>
              </thead>
              <tbody>
                {templateData.newInvestmentsTable.map(
                  (investment: any, index: number) => {
                    let totalInvestment =
                      investment.residual + investment.realized;
                    let percentage =
                      (investment.realized * 100) / totalInvestment;

                    let roundedPercentage = parseFloat(percentage.toFixed(2));
                    line++;
                    if (line < maxLines) {
                      return (
                        <tr key={index}>
                          <td>{investment.fund}</td>
                          <td>{investment.name}</td>
                          <td>
                            {investment.country} <br />
                            {truncateText(investment.activity)}
                          </td>
                          <td>{investment.date}</td>
                          <td>
                            <div
                              className="progress-bar"
                              style={{
                                background: `linear-gradient(to right, #c4c4c4 ${roundedPercentage}%, #e6e6e6 ${roundedPercentage}%)`,
                              }}
                            >
                              <p>€ {formatNumber(totalInvestment, 0)}</p>
                            </div>
                          </td>
                        </tr>
                      );
                    } else {
                      outOfBoundElements.newInvestments.push(investment);
                    }
                  }
                )}
              </tbody>
            </table>
			) : (
			  <div className="empty-box">{translations.noInvestments}</div>
			)}
          </section>
          <section className="newInvestments-content">
            <p>{translations.newRealizations}</p>

            {templateData.newRealizationsTable.length > 0 ? (
              <table className="newInvestments-table">
                <colgroup>
                  <col style={{ width: "150px" }} />
                  <col style={{ width: "150px" }} />
                  <col style={{ width: "150px" }} />
                  <col style={{ width: "150px" }} />
                  <col style={{ width: "150px" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>{translations.fund}</th>
                    <th>{translations.name}</th>
                    <th>{translations.countryAndActivity}</th>
                    <th>{translations.entry}</th>
                    <th>{translations.investment}</th>
                  </tr>
                </thead>
                <tbody>
                  {templateData.newRealizationsTable.map(
                    (investment: any, index: number) => {
                      let totalInvestment =
                        investment.residual + investment.realized;
                      let percentage =
                        (investment.realized * 100) / totalInvestment;
                      let roundedPercentage = parseFloat(percentage.toFixed(2));

                      line++;
                      if (line < maxLines) {
                        return (
                          <tr key={index}>
                            <td>{investment.fund}</td>
                            <td>{investment.name}</td>
                            <td>
                              {investment.country} <br /> {investment.activity}
                            </td>
                            <td>{investment.date}</td>
                            <td>
                              <div
                                className="progress-bar"
                                style={{
                                  background: `linear-gradient(to right, #c4c4c4 ${roundedPercentage}%, #e6e6e6 ${roundedPercentage}%)`,
                                }}
                              >
                                <p>€ {formatNumber(totalInvestment, 0)}</p>
                              </div>
                            </td>
                          </tr>
                        );
                      } else {
                        outOfBoundElements.newRealizations.push(investment);
                      }
                    }
                  )}
                </tbody>
              </table>
            ) : (
              <div className="empty-box">{translations.noRealizations}</div>
            )}
            <div className="newInvestments-references">
              <div className="reference ref-1" />
              {translations.ref1}
              <div className="reference ref-2" />
              {translations.ref2}
            </div>
          </section>
        </>,
        page
      )}
      {/* Page 2 */}
      {(outOfBoundElements.newInvestments.length > 0 ||
        outOfBoundElements.newRealizations.length > 0) && (
        <>
          {renderPage(
            <>
              {outOfBoundElements.newInvestments.length > 0 && (
                <section className="newInvestments-content">
                  <p>{translations.newInvestments}</p>
                  <table className="newInvestments-table">
                    <colgroup>
                      <col style={{ width: "150px" }} />
                      <col style={{ width: "150px" }} />
                      <col style={{ width: "150px" }} />
                      <col style={{ width: "150px" }} />
                      <col style={{ width: "150px" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>{translations.fund}</th>
                        <th>{translations.name}</th>
                        <th>{translations.countryAndActivity}</th>
                        <th>{translations.entry}</th>
                        <th>{translations.investment}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {outOfBoundElements.newInvestments.map(
                        (investment: any, index: number) => {
                          let totalInvestment =
                            investment.residual + investment.realized;
                          let percentage =
                            (investment.realized * 100) / totalInvestment;

							let roundedPercentage = parseFloat(percentage.toFixed(2));

                          return (
                            <tr key={index}>
                              <td>{investment.fund}</td>
                              <td>{investment.name}</td>
                              <td>
                                {investment.country} <br />{" "}
                                {investment.activity}
                              </td>
                              <td>{investment.date}</td>
                              <td>
                                <div
                                  className="progress-bar"
                                  style={{
                                    background: `linear-gradient(to right, #c4c4c4 ${roundedPercentage}%, #e6e6e6 ${roundedPercentage}%)`,
                                  }}
                                >
                                  <p>€ {formatNumber(totalInvestment, 0)}</p>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </section>
              )}
              {outOfBoundElements.newRealizations.length > 0 && (
                <section className="newInvestments-content">
                  <p>{translations.newRealizations}</p>
                  <table className="newInvestments-table">
                    <colgroup>
                      <col style={{ width: "150px" }} />
                      <col style={{ width: "150px" }} />
                      <col style={{ width: "150px" }} />
                      <col style={{ width: "150px" }} />
                      <col style={{ width: "150px" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>{translations.fund}</th>
                        <th>{translations.name}</th>
                        <th>{translations.countryAndActivity}</th>
                        <th>{translations.entry}</th>
                        <th>{translations.investment}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {outOfBoundElements.newRealizations.map(
                        (investment: any, index: number) => {
                          let totalInvestment =
                            investment.residual + investment.realized;
                          let percentage =
                            (investment.realized * 100) / totalInvestment;

							let roundedPercentage = parseFloat(percentage.toFixed(2));

                          return (
                            <tr key={index}>
                              <td>{investment.fund}</td>
                              <td>{investment.name}</td>
                              <td>
                                {investment.country} <br />{" "}
                                {investment.activity}
                              </td>
                              <td>{investment.date}</td>
                              <td>
                                <div
                                  className="progress-bar"
                                  style={{
                                    background: `linear-gradient(to right, #c4c4c4 ${roundedPercentage}%, #e6e6e6 ${roundedPercentage}%)`,
                                  }}
                                >
                                  <p>€ {formatNumber(totalInvestment, 0)}</p>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </section>
              )}
            </>,
            page + 1
          )}
        </>
      )}
    </>
  );
};

export default NewInvestments;
