import ReportHeader from "../00-Header/ReportHeader";
import { IGlossary } from "./IGlossary";

const GlossaryP3Es = ({ templateData, date }: IGlossary) => (
  <div id="glossary" contentEditable className="pagebreak">
    <ReportHeader
      programName={templateData.programName}
      confidential="CONFIDENCIAL"
      date={date}
      section="Glosario de términos"
      page={templateData.page}
    />
    <main className="glossary">
      <section className="glossary-content">
        <div className="glossary-term-list">
          <p className="glossary-term-item-bold">M</p>
          <p className="glossary-term-item">Millones</p>
          <p className="glossary-term-item-bold">MOIC</p>
          <div className="glossary-term-item-special">
            <p className="glossary-term-item-special-text">
              "Multiple on lnvested Capital". Múltiplo sobre capital invertido.
              Se usa para medir la rentabilidad de la inversión en una compañía
              subyacente. Calculado como:
            </p>
            <div className="glossary-term-item-form">
              <p>MOIC =</p>
              <div className="centered">
                <p className="glossary-term-item-lined">
                  Valor actual de la inversión
                </p>
                <p>Coste de la inversión</p>
              </div>
            </div>
          </div>
          <p className="glossary-term-item-bold">NAV</p>
          <p className="glossary-term-item">
            “Net Asset Value”. Es el valor real de las inversiones en un momento
            determinado.
          </p>
          <p className="glossary-term-item-bold">NOK</p>
          <p className="glossary-term-item">Corona noruega.</p>
          <p className="glossary-term-item-bold">p.a.</p>
          <p className="glossary-term-item">Per annum. Por año / anualmente.</p>
          <p className="glossary-term-item-bold">Private equity</p>
          <p className="glossary-term-item">
            Capital Privado. Toma de participaciones mayoritarias o minoritarias
            en empresas no cotizadas durante un plazo de tiempo que permita la
            ejecución de un plan de negocio establecido en el momento de la
            adquisición, con el objetivo de realizar una plusvalía al vender la
            empresa tras el periodo de tenencia.
          </p>
          <p className="glossary-term-item-bold">Proforma</p>
          <p className="glossary-term-item">
            Magnitud que refleja operaciones desarrolladas con posterioridad a
            la fecha de la valoración a la que hace referencia.
          </p>
          <p className="glossary-term-item-bold">Q1</p>
          <p className="glossary-term-item">
            Primer trimestre del año que termina el 31 de marzo.
          </p>
          <p className="glossary-term-item-bold">Q2</p>
          <p className="glossary-term-item">
            Segundo trimestre del año que termina el 30 de junio.
          </p>
          <p className="glossary-term-item-bold">Q3</p>
          <p className="glossary-term-item">
            Tercer trimestre del año que termina el 30 de septiembre.
          </p>
          <p className="glossary-term-item-bold">Q4</p>
          <p className="glossary-term-item">
            Cuarto trimestre del año que termina el 31 de diciembre.
          </p>
          <p className="glossary-term-item-bold">RPI o RVPI</p>
          <div className="glossary-term-item-special">
            <p className="glossary-term-item-special-text">
              "Residual Value to Paid-In Capital". Valor residual de la cartera
              viva sobre el importe desembolsado, calculado como:
            </p>
            <div className="glossary-term-item-form">
              <p>RVPI =</p>
              <div className="centered">
                <p className="glossary-term-item-lined">
                  Valor liquidativo ∗ Número de participaciones vivas
                </p>
                <p>Total desembolsado</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
);

export default GlossaryP3Es;
