import { AxiosResponse } from 'axios';

import API from './api';

import { IAPI } from './IAPI';

import { NetCash } from '../Models/NetCash';

const FormAPI = ({ baseUrl, getAuthToken, showServiceFeedbackModal }: IAPI) => {
    const api = API(baseUrl!, getAuthToken);

    const getNetCash = () => {
		const serviceUrl = '/reporting-api/NetCash';
		return api.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => showServiceFeedbackModal(error.response.status, `${baseUrl}${serviceUrl}`));
    };

	const saveNetCash = (data: NetCash) => {
		const serviceUrl = '/reporting-api/NetCash';
		return api.post(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => showServiceFeedbackModal(error.response.status, `${baseUrl}${serviceUrl}`));
	};

	const updateNetCash = (data: NetCash) => {
		const serviceUrl = '/reporting-api/NetCash';
		return api.put(serviceUrl, data)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => showServiceFeedbackModal(error.response.status, `${baseUrl}${serviceUrl}`));
	};

	const deleteNetCash = (data: NetCash) => {
		const serviceUrl = '/reporting-api/NetCash';
		return api.delete(`${serviceUrl}?idNetCash=${data.id}`)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => showServiceFeedbackModal(error.response.status, `${baseUrl}${serviceUrl}`));
	};

	const getFunds = () => {
		const serviceUrl = '/reporting-api/Quarterly/Funds';
		return api.get(serviceUrl)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => showServiceFeedbackModal(error.response.status, `${baseUrl}${serviceUrl}`));
	};

    return {
        getNetCash,
        saveNetCash,
		updateNetCash,
		deleteNetCash,
		getFunds
    };
};

export default FormAPI;