
import { IGlossary } from "./IGlossary";

import "./Glossary.scss";
import GlossaryP1Es from "./GlossaryP1Es";
import GlossaryP1En from "./GlossaryP1En";
import GlossaryP2Es from "./GlossaryP2Es";
import GlossaryP2En from "./GlossaryP2En";
import GlossaryP3Es from "./GlossaryP3Es";
import GlossaryP3En from "./GlossaryP3En";
import GlossaryP4Es from "./GlossaryP4Es";
import GlossaryP4En from "./GlossaryP4En";

const Glossary = ({ templateData, translations, language }: IGlossary) => {
  const date = `${translations[templateData.month]} ${templateData.year}`;
  const page = templateData.page;
  return (
    <>
      {/* PAGE 1 */}
      {language === "es" ? (
        <GlossaryP1Es
          templateData={{ ...templateData, page }}
          date={date}
        />
      ) : (
        <GlossaryP1En
          templateData={{ ...templateData, page }}
          date={date}
        />
      )}
      {/* PAGE 2 */}
      {language === "es" ? (
        <GlossaryP2Es
          templateData={{ ...templateData, page: page + 1 }}
          date={date}
        />
      ) : (
        <GlossaryP2En
          templateData={{ ...templateData, page: page + 1 }}
          date={date}
        />
      )}
      {/* PAGE 3*/}
      {language === "es" ? (
        <GlossaryP3Es
          templateData={{ ...templateData, page: page + 2 }}
          date={date}
        />
      ) : (
        <GlossaryP3En
          templateData={{ ...templateData, page: page + 2 }}
          date={date}
        />
      )}
      {/* PAGE 4*/}
      {language === "es" ? (
        <GlossaryP4Es
          templateData={{ ...templateData, page: page + 3 }}
          date={date}
        />
      ) : (
        <GlossaryP4En
          templateData={{ ...templateData, page: page + 3 }}
          date={date}
        />
      )}
    </>
  );
};

export default Glossary;
